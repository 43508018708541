import type { UserInterest, UserProfile } from '@beelday/common'
import { userApi } from '@beelday/common'
import { KnowledgeCheckID } from '../features/knowledge-checks/knowledge-check'
import { PollingWordCloudId } from '../features/pollings/word-cloud/world-cloud-models'
import { SlideId } from '../features/slides/slide-models'
import { AudioStreamId } from 'features/audio-streaming/model'

export enum EventsSourcePath {
	workflow,
	interactionScheme,
	room,
}

export enum HandPosition {
	UP = 'UP',
	DOWN = 'DOWN',
}

export type PermissionMode = 'NO_ONE' | 'PER_REQUEST' | 'EVERYONE'

export enum TrainerUnavailableReason {
	IN_OTHER_GROUP = 'IN_OTHER_GROUP',
	BE_RIGHT_BACK = 'BE_RIGHT_BACK',
}

export type GroupSuggestionAssignmentUser = {
	userId: UserId
	final: boolean
}

export type GroupId = string

export enum Color {
	PURPLE = 'purple',
	BLUE = 'blue',
	GRAY = 'gray',
	PINK = 'pink',
	GREEN = 'green',
	YELLOW = 'yellow',
	NAVY = 'navy',
	ORANGE = 'orange',
	WHITE = 'white',
	INDIGO = 'indigo',
}

export type ColorKeys = keyof typeof Color

export type GroupDescription = {
	id: string
	name: string
	color: Color
}

export type GroupSuggestionDescription = {
	id: string
	name: string
	color: Color
	maxGroupSize: number
}

export type GroupState = {
	groupDescription: GroupDescription
	users: User[]
}

export type Group = GroupState & {
	attention: GroupAttention
}

export type Task = {
	name: string
}

type ExternalId = { value: string }

type UsedMedia = { type: 'ETHERPAD'; padId: ExternalId }

type TaskStatus = 'COMPLETED' | 'IN_REVIEW'

export type GroupReview = {
	id: GroupId
	properties: { color: Color; name: string; number: number }
	members: UserKey[]
	task: {
		name: string
		description: string
		status: TaskStatus
		usedMedia: UsedMedia[]
	}
}

export type None = { type: 'NONE' }
export type RaisedHand = { type: 'RAISED_HAND' }
export type TrainerJoining = { type: 'TRAINER_JOINING'; countdown: number }
export type TrainerUnavailableBecauseInOtherGroup = {
	type: 'TRAINER_UNAVAILABLE'
	reason: TrainerUnavailableReason.IN_OTHER_GROUP
}
export type TrainerUnavailableBecauseTrainerBeRightBack = {
	type: 'TRAINER_UNAVAILABLE'
	reason: TrainerUnavailableReason.BE_RIGHT_BACK
}
export type TrainerUnavailable =
	| TrainerUnavailableBecauseInOtherGroup
	| TrainerUnavailableBecauseTrainerBeRightBack

export type GroupAttention =
	| None
	| RaisedHand
	| TrainerJoining
	| TrainerUnavailable

export type GroupSuggestionAssignment = {
	groupDescription: GroupSuggestionDescription
	users: GroupSuggestionAssignmentUser[]
}

export type GroupSuggestionAssignmentDto = {
	groupId: string
	color: string
	name: string
	maxGroupSize: number
	users: [GroupSuggestionAssignmentUser]
}

export enum PartitionMode {
	None,
	Manual = 'Manual',
	Automatic = 'Automatic',
}

export enum AssignMode {
	None,
	Manual = 'Manual',
	Automatic = 'Automatic',
}

export type RoomId = string
export type VcrId = string
export type VcrConfig = {
	publish: boolean
	subscribe: boolean
}

export type InteractionSchemeRoomState = {
	roomId: RoomId
	roomType: RoomType
	users: string[]
}

export type InteractionSchemeStateChangeEvent = {
	timestamp: Date
	state: {
		roomStates: InteractionSchemeRoomState[]
	}
	isPhase: TrainingPhaseName
}

type TransferPayloadGroup = GroupKey & {
	users: { userId: UserId }[]
	name: string
	color: Color
}

//TODO: get a list of all features
export type FeaturesChangedEvent = {
	eventType: 'FEATURES_CHANGED'
	context: InteractionSchemeAddress | RoomAddress
	enabled: FeatureType[]
}
export type InteractionSchemeRoomTransferWithCountdownEvent = {
	countdown: number
	fromRoom: RoomId
	toRoom: RoomId
	payload:
		| {
				type: 'TO_GROUP_ROOM'
				groups: TransferPayloadGroup[]
		  }
		| {
				type: 'TO_TRAINING_ROOM'
		  }
		| {
				type: 'TO_LOBBY'
		  }
		| {
				type: 'TO_PUBLIC_CHAT'
		  }
		| {
				type: 'EMPTY'
		  }
		| {
				type: 'TO_TRAINING_ROOM_WITH_GROUP_WORK_RESULTS'
		  }
	transferAll: boolean
	usersToTransfer: UserId[]
}

export enum RoomType {
	Lobby = 'lobby',
	TrainingRoom = 'trainingRoom',
	WebinarRoom = 'webinarRoom',
	GroupRoom = 'groupRoom',
	PublicChat = 'publicChat',
}

export type InteractionSchemeState = {
	users: User[]
	id: string
}

export type WorkflowEvent = {
	timestamp: Date
	workflowState: {
		interactionSchemesState: InteractionSchemeState[]
	}
}

export type WorkflowId = string

export type WorkflowKey = {
	workflowId: string
}

export type InteractionSchemeKey = {
	interactionSchemeId: string
}
export type RoomKey = {
	roomId: RoomId
	roomType: RoomType
}

export type GroupKey = {
	groupId: string
}

export type InteractionSchemeAddress = WorkflowKey & InteractionSchemeKey

export type RoomAddress = InteractionSchemeAddress & RoomKey

export type GroupAddress = RoomAddress & GroupKey

export enum UserRole {
	UPPER_ECHELON = 'UPPER_ECHELON',
	END_USER = 'END_USER',
}

export type UserId = string

export type UserKey = {
	id: UserId
}

export type User = UserKey & {
	role: UserRole
	name: string
}

export function isReviewUser(user: ReviewUser | User): user is ReviewUser {
	return (user as UngroupedUser).group == null || 'group' in user
}

export type ReviewUser = UngroupedUser | GroupMember

export type UngroupedUser = User & {
	group: null | undefined
}

export type GroupMember = UserKey & {
	group: {
		id: GroupId
		name: string
		color: Color
		number: number
	}
	identifiedBy: 'GROUP_COLOR' | 'GROUP_NUMBER'
}

export type SpotlightChange = {
	spotlight: boolean
	userId: UserId
}

export type GroupsOfSize = {
	groupsCount: number
	groupsSize: number
}

export type GroupPartitionMap = {
	maxGroupSize: number
	groups: GroupsOfSize[]
}[]

export enum TrainingRoomPhase {
	INITIAL = 'INITIAL',
	SELECTING_GROUPS = 'SELECTING_GROUPS',
	REVIEWING_TASKS = 'REVIEWING_TASKS',
}

export type EtherPadState = {
	active: boolean
	loaded: boolean
}

export type ExcalidrawState = {
	active: boolean
	loaded: boolean
}

export type ScreenShareInGroupState = {
	active: boolean
	userId?: UserId
}

export type UserInfo = {
	firstName: string
	lastName: string
	email: string
	phoneNumber?: string
}

export type Media = 'EtherPad' | 'Excalidraw'

export type QuizId = string

export type QuizAnswerId = string

export type QuizUser = {
	id: UserId
	displayName?: string
	name?: string
}

export type FileId = string

export type QuizQuestionAsked = {
	quizId: QuizId
	number: number
	totalQuestions: number
	text: string
	imageId: FileId
	availableAnswers: [
		{
			id: QuizAnswerId
			text: string
		}
	]
}

export type QuizQuestionAnswered = {
	quizId: QuizId
	numberOfAnswers: number
	expectedNumberOfAnswers: number
	availableAnswers: [
		{
			id: QuizAnswerId
			playersWhoSelectedThisAnswer: QuizUser[]
		}
	]
}

export type QuizQuestionCompleted = {
	quizId: QuizId
	numberOfAnswers: number
	expectedNumberOfAnswers: number
	availableAnswers: [
		{
			id: QuizAnswerId
			correct: boolean
			playersWhoSelectedThisAnswer: QuizUser[]
		}
	]
}

export type Avatar = userApi.Avatar

export type Interest = UserInterest
export type { UserProfile }

export type PROFILE_MISSING = null

export type UUID = string

export type TrainingPhaseName =
	| 'TASK_WORK'
	| 'TRAINING_BREAK'
	| 'WORKING_TOGETHER'

export type TrainingPhase = {
	currentPhase: TrainingPhaseName
	previousPhase?: TrainingPhaseName
}

export type FeatureType =
	| 'CameraType'
	| 'MicrophoneType'
	| 'ConferenceCallFeatureType'
	| 'FocusFeatureType'
	| 'SimpleGrouping'
	| 'Grouping'
	| 'GroupingDefinitionFeatureType'
	| 'KnowledgeCheckDefinitionType'
	| 'KnowledgeCheckType'
	| 'ListPollingFeatureType'
	| 'MultipleChoicePollingFeatureType'
	| 'NotepadFeatureType'
	| 'Question'
	| 'QuizType'
	| 'ScreenType'
	| 'Slide'
	| 'SlideDefinitionFeatureType'
	| 'WhiteboardFeatureType'
	| 'WordCloudPollingFeatureType'
	| 'AudioStreamFeatureType'

export enum FocusedFeatureTypes {
	ScreenShare = 'ScreenType',
	KNOWLEDGE_CHECK = 'KnowledgeCheckType',
	ICEBREAKER = 'QuizType',
	SLIDE = 'Slide',
	POLLING_OPEN_ENDED_WORD_CLOUD = 'WordCloudPollingFeatureType',
	POLLING_OPEN_ENDED_LIST = 'ListPollingFeatureType',
	POLLING_MULTIPLE_CHOICE = 'MultipleChoicePollingFeatureType',
	WHITEBOARD = 'WhiteboardFeatureType',
	NOTEPAD = 'NotepadFeatureType',
	GROUPING = 'Grouping',
	SIMPLE_GROUPING = 'SimpleGrouping',
	AUDIO_STREAMING = 'AudioStreamFeatureType',
}

export type QuizFocusFeature = {
	quizId: QuizId
	type: FocusedFeatureTypes.ICEBREAKER
}

export type KnowledgeCheckFocusFeature = {
	knowledgeCheckId: KnowledgeCheckID
	type: FocusedFeatureTypes.KNOWLEDGE_CHECK
}

export type SlideFocusedFeature = {
	type: FocusedFeatureTypes.SLIDE
	slideId: SlideId
}

export type ScreenShareFocusFeature = {
	type: FocusedFeatureTypes.ScreenShare
}

export type WordCloudFocusFeature = {
	type: FocusedFeatureTypes.POLLING_OPEN_ENDED_WORD_CLOUD
	pollingId: PollingWordCloudId
}

export type ListPollingFocusFeature = {
	type: FocusedFeatureTypes.POLLING_OPEN_ENDED_LIST
	pollingId: PollingWordCloudId
}

export type MultipleChoicePollingFocusFeature = {
	type: FocusedFeatureTypes.POLLING_MULTIPLE_CHOICE
	pollingId: PollingWordCloudId
}

export type WhiteboardFocusFeature = {
	type: FocusedFeatureTypes.WHITEBOARD
	whiteboardId: UUID
}

export type AudioStreamFocusFeature = {
	type: FocusedFeatureTypes.AUDIO_STREAMING
	audioStreamId: AudioStreamId
}

export type NotepadFocusFeature = {
	type: FocusedFeatureTypes.NOTEPAD
	readOnlyNotepadId: UUID
	writableNotepadId: UUID
}

export type GroupingFocusFeature = {
	type: 'Grouping'
}

export type SimpleGroupingFeature = {
	type: FocusedFeatureTypes.SIMPLE_GROUPING
}

export type FocusFeature =
	| SimpleGroupingFeature
	| QuizFocusFeature
	| KnowledgeCheckFocusFeature
	| ScreenShareFocusFeature
	| SlideFocusedFeature
	| WordCloudFocusFeature
	| ListPollingFocusFeature
	| MultipleChoicePollingFocusFeature
	| WhiteboardFocusFeature
	| NotepadFocusFeature
	| GroupingFocusFeature
	| AudioStreamFocusFeature

export const isRoomAddress = (
	address: RoomAddress | InteractionSchemeAddress
): address is RoomAddress => {
	return !!(address as RoomAddress).roomId
}

export const isInteractionSchemeAddress = (
	address: RoomAddress | InteractionSchemeAddress
): address is InteractionSchemeAddress => {
	return !!(address.interactionSchemeId && !(address as RoomAddress).roomId)
}
