import { hooks, intl, ui } from '@beelday/common'
import { RootState } from 'common/redux'
import {
	FocusFeature,
	FocusedFeatureTypes,
	TrainingRoomPhase,
} from 'common/types'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { selectIsKnowledgeCheckAvaliable } from 'features/knowledge-checks/knowledge-checks-redux'
import StartKnowledgeCheck from 'features/knowledge-checks/start-knowledge-check'
import { MicMenuItem } from 'features/microphone'
import { useListPolling } from 'features/pollings/list-polling'
import { useMultipleChoicePolling } from 'features/pollings/multiple-choice-polling'
import { useWordCloud } from 'features/pollings/word-cloud'
import { useQuestions } from 'features/questions'
import { ConfiguredSlideSwitcher } from 'features/slides/configured-slide-switcher'
import { selectAreSlidesAvaliable } from 'features/slides/slide-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AgendaIcon } from 'room/common/presentation/icon/agenda'
import { Back } from 'room/common/presentation/icon/back'
import {
	Menu,
	MenuItem,
	MenuItemBubble,
	MenuItemLine,
	MenuToggleItem,
} from 'room/common/presentation/menu'
import { roomEndUsers, selectEnabledFeatures } from 'room/common/redux'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { isEnoughUsersToStartGroupWork } from 'room/training-room/set-groups/model/set-groups'
import SetGroupsParametersPanel from 'room/training-room/set-groups/presentation/group-parameters-panel/set-groups-parameters-panel'
import { v4 as uuidv4 } from 'uuid'
import { Break } from '../common/presentation/icon/break'
import { Grouping } from '../common/presentation/icon/grouping'
import { Polling } from '../common/presentation/icon/polling'
import { Question } from '../common/presentation/icon/question'
import { Quiz } from '../common/presentation/icon/quiz'
import MiscPanelMenu from '../misc/misc-panel-menu'
import StartPollingsPopup from './polling/start-pollings-popup'
import { selectFocusedFeature } from './redux'
import { ExcalidrawMenu } from './excalidraw/excalidraw-menu'
import { Excalidraw } from '../common/presentation/icon/excalidraw'
import { NotepadMenu } from './etherpad/etherpad-menu'
import { Document } from 'room/common/presentation/icon/document'
import CamMenuItem from 'features/camera/camera-menu-item'
import ScreenShareTrainerMenuItem from 'features/screen-share/screen-share-trainer-menu-item'
import { useSimpleGrouping } from 'features/simple-grouping/use-simple-feature'
import AudioStreamMenuItem from 'features/audio-streaming/audio-streaming-menu-item'
import { StartAudioStream } from 'features/audio-streaming/audio-streaming-start'
import useAudioStreamingApi from 'features/audio-streaming/audio-streaming-api'
import useMicApi from 'features/microphone/microphone-api'
import { Config } from 'common/config'
import { useAssertedWorkflowUser } from 'room/common/use-workflow-user'

const isFeatureAcive = (
	phase: TrainingRoomPhase,
	focusedFeature: FocusFeature,
	questionActive: boolean
): boolean => {
	return !(
		phase === TrainingRoomPhase.INITIAL &&
		!focusedFeature &&
		!questionActive
	)
}

export const TrainerMenu: React.FC = () => {
	const beeldayClient = useAuthenticatedBeeldayClient()
	const joinedRoomAddress = useAssertedJoinedRoomAddress()
	const questions = useQuestions()
	const wordCloud = useWordCloud()
	const simpleGrouping = useSimpleGrouping()
	const listPolling = useListPolling()
	const multipleChoicePolling = useMultipleChoicePolling()
	const enabledFeatures = useSelector(selectEnabledFeatures)
	const audioStreaming = useAudioStreamingApi()
	const micApi = useMicApi(Config.beeldayBackendUrl)
	const assertedUser = useAssertedWorkflowUser()

	const [menuHeight, setMenuHeight] = useState(0)

	const focusedFeature = useSelector(selectFocusedFeature)
	const slidesActive = focusedFeature?.type === FocusedFeatureTypes.SLIDE
	const simpleGroupingFocused =
		focusedFeature?.type === FocusedFeatureTypes.SIMPLE_GROUPING
	const pollingActive =
		focusedFeature?.type === FocusedFeatureTypes.POLLING_MULTIPLE_CHOICE ||
		focusedFeature?.type ===
			FocusedFeatureTypes.POLLING_OPEN_ENDED_WORD_CLOUD ||
		focusedFeature?.type === FocusedFeatureTypes.POLLING_OPEN_ENDED_LIST

	const trainingRoomPhase = useSelector(
		(state: RootState) => state.room.trainingRoomPhase
	)

	const endUsers = useSelector(roomEndUsers)
	const canStartGroupWork = useMemo(
		() => isEnoughUsersToStartGroupWork(endUsers),
		[endUsers]
	)

	const [showKnowledgeChecksConfig, setShowKnowledgeChecksConfig] =
		useState(false)
	const [showStartPollsConfig, setShowStartPollsConfig] = useState(false)
	const [showGroupConfig, setShowGroupConfig] = useState(false)
	const [showSlidesSwitcher, setShowSlidesSwitcher] = useState(false)
	const [showStartAudioStreamConfig, setShowStartAudioStreamConfig] =
		useState(false)

	useEffect(() => {
		//Hide slide switcher when trainer closes slides
		if (!slidesActive) {
			setShowSlidesSwitcher(false)
		}
	}, [slidesActive])

	const featureActive = isFeatureAcive(
		trainingRoomPhase,
		focusedFeature,
		questions.active
	)

	const toggleSimpleGrouping = hooks.useSerializedCallback(
		() =>
			simpleGrouping.started
				? simpleGrouping.finish(joinedRoomAddress)
				: simpleGrouping.start(joinedRoomAddress),
		[joinedRoomAddress, simpleGrouping]
	)
	const startVoting = hooks.useSerializedCallback(
		() => questions.startVoting(joinedRoomAddress),
		[joinedRoomAddress]
	)
	const startQuiz = hooks.useSerializedCallback(() => {
		const quizId = uuidv4()
		return beeldayClient.startQuiz({ ...joinedRoomAddress, quizId })
	}, [joinedRoomAddress])

	const startBreak = hooks.useSerializedCallback(() => {
		if (focusedFeature?.type === FocusedFeatureTypes.AUDIO_STREAMING) {
			audioStreaming.removeCurrent(joinedRoomAddress)
		}
		return beeldayClient.startBreak(joinedRoomAddress)
	}, [joinedRoomAddress])
	const toggleAudioStream = useCallback(() => {
		if (!focusedFeature) {
			setShowStartAudioStreamConfig(true)
		} else if (focusedFeature.type === FocusedFeatureTypes.AUDIO_STREAMING) {
			audioStreaming
				.removeCurrent(joinedRoomAddress)
				.then(() => micApi.unmuteMic(joinedRoomAddress, assertedUser.id))
		}
	}, [
		assertedUser.id,
		audioStreaming,
		focusedFeature,
		joinedRoomAddress,
		micApi,
	])

	const stopPolling = useCallback(() => {
		if (!focusedFeature) {
			setShowStartPollsConfig(true)
		} else if (
			focusedFeature.type === FocusedFeatureTypes.POLLING_OPEN_ENDED_WORD_CLOUD
		) {
			wordCloud.stopPolling(focusedFeature.pollingId)
		} else if (
			focusedFeature.type === FocusedFeatureTypes.POLLING_OPEN_ENDED_LIST
		) {
			listPolling.stopPolling(focusedFeature.pollingId)
		} else if (
			focusedFeature.type === FocusedFeatureTypes.POLLING_MULTIPLE_CHOICE
		) {
			multipleChoicePolling.stopPolling(focusedFeature.pollingId)
		}
	}, [focusedFeature, listPolling, multipleChoicePolling, wordCloud])

	const isPollingAvailable = useSelector(selectIsKnowledgeCheckAvaliable)

	const isSlideAvaliable = useSelector(selectAreSlidesAvaliable)
	const pollingFeatureActive =
		(featureActive &&
			focusedFeature?.type ===
				FocusedFeatureTypes.POLLING_OPEN_ENDED_WORD_CLOUD) ||
		focusedFeature?.type === FocusedFeatureTypes.POLLING_OPEN_ENDED_LIST ||
		focusedFeature?.type === FocusedFeatureTypes.POLLING_MULTIPLE_CHOICE

	let pollingButtonDisabled = false

	if (featureActive && !pollingActive) {
		pollingButtonDisabled = true
	}

	const isWhiteboardActive =
		focusedFeature?.type === FocusedFeatureTypes.WHITEBOARD

	const isNotepadActive = focusedFeature?.type === FocusedFeatureTypes.NOTEPAD

	return (
		<ui.Relative>
			<ConfiguredSlideSwitcher
				visible={showSlidesSwitcher}
				onClose={() => setShowSlidesSwitcher(false)}
				offset={menuHeight}
			/>
			<Menu
				ref={e => e && setMenuHeight(e.scrollHeight)}
				style={{ zIndex: 2, position: 'relative' }}
			>
				<MenuToggleItem
					hidden={!enabledFeatures.includes('Grouping')}
					disabled={featureActive || !canStartGroupWork}
					icon={<Grouping />}
					active={showGroupConfig}
					onClick={() => setShowGroupConfig(!showGroupConfig)}
					label={<intl.Translate>room_menu.group</intl.Translate>}
				>
					{showGroupConfig ? (
						<MenuItemBubble>
							<SetGroupsParametersPanel
								onCancel={() => setShowGroupConfig(false)}
								onDone={() => setShowGroupConfig(false)}
							/>
						</MenuItemBubble>
					) : null}
				</MenuToggleItem>
				<MenuToggleItem
					hidden={!enabledFeatures.includes('SimpleGrouping')}
					icon={<Grouping />}
					active={simpleGroupingFocused && simpleGrouping.started}
					onClick={toggleSimpleGrouping}
					label={<intl.Translate>room_menu.group</intl.Translate>}
				/>
				<AudioStreamMenuItem
					onClick={toggleAudioStream}
					canStart={!featureActive}
				/>
				<ScreenShareTrainerMenuItem
					hidden={!enabledFeatures.includes('ScreenType')}
					canStart={!featureActive}
				/>
				<MenuToggleItem
					hidden={!enabledFeatures.includes('QuizType')}
					disabled={featureActive}
					icon={<Quiz />}
					onClick={startQuiz}
					label={<intl.Translate>room_menu.quiz</intl.Translate>}
				/>
				<MenuToggleItem
					hidden={!enabledFeatures.includes('Question')}
					disabled={featureActive}
					icon={<Question />}
					onClick={startVoting}
					label={<intl.Translate>room_menu.question</intl.Translate>}
				/>
				<MenuToggleItem
					hidden={
						!enabledFeatures.includes('KnowledgeCheckType') &&
						!enabledFeatures.includes('KnowledgeCheckDefinitionType')
					}
					disabled={featureActive || !isPollingAvailable}
					icon={<Polling />}
					onClick={() =>
						setShowKnowledgeChecksConfig(!showKnowledgeChecksConfig)
					}
					label={<intl.Translate>room_menu.knowledge_check</intl.Translate>}
				/>
				<MenuToggleItem
					hidden={
						!enabledFeatures.includes('ListPollingFeatureType') &&
						!enabledFeatures.includes('WordCloudPollingFeatureType') &&
						!enabledFeatures.includes('MultipleChoicePollingFeatureType')
					}
					disabled={pollingButtonDisabled}
					icon={pollingFeatureActive ? <Back /> : <Polling />}
					onClick={() => stopPolling()}
					label={<intl.Translate>room_menu.polling</intl.Translate>}
				/>
				<MenuToggleItem
					hidden={!enabledFeatures.includes('Slide')}
					disabled={!isSlideAvaliable}
					icon={<AgendaIcon />}
					onClick={() =>
						showSlidesSwitcher
							? setShowSlidesSwitcher(false)
							: setShowSlidesSwitcher(true)
					}
					label={<intl.Translate>room_menu.slides</intl.Translate>}
				/>

				<MenuToggleItem
					hidden={!enabledFeatures.includes('WhiteboardFeatureType')}
					disabled={featureActive && !isWhiteboardActive}
					active={isWhiteboardActive}
					icon={<Excalidraw />}
					label={<intl.Translate>room_menu.excalidraw</intl.Translate>}
				>
					{!(featureActive && !isWhiteboardActive) ? (
						<ExcalidrawMenu
							activeWhiteboardId={
								isWhiteboardActive ? focusedFeature.whiteboardId : undefined
							}
							withFocus={true}
						/>
					) : null}
				</MenuToggleItem>
				<MenuToggleItem
					hidden={!enabledFeatures.includes('NotepadFeatureType')}
					disabled={featureActive && !isNotepadActive}
					active={isNotepadActive}
					icon={<Document />}
					label={<intl.Translate>room_menu.etherpad</intl.Translate>}
				>
					{!(featureActive && !isNotepadActive) ? (
						<NotepadMenu
							activeNotepadId={
								isNotepadActive ? focusedFeature.readOnlyNotepadId : undefined
							}
							withFocus={true}
						/>
					) : null}
				</MenuToggleItem>
				<MicMenuItem />
				<CamMenuItem />
				<MenuItem
					icon={<Break />}
					onClick={startBreak}
					label={<intl.Translate>room_menu.break</intl.Translate>}
				/>
				<MenuItemLine />
				<MiscPanelMenu />
			</Menu>
			{showKnowledgeChecksConfig ? (
				<StartKnowledgeCheck
					roomAddress={joinedRoomAddress}
					onClose={() => setShowKnowledgeChecksConfig(false)}
				/>
			) : null}
			{showStartPollsConfig ? (
				<StartPollingsPopup onClose={() => setShowStartPollsConfig(false)} />
			) : null}
			{showStartAudioStreamConfig ? (
				<StartAudioStream
					onClose={() => setShowStartAudioStreamConfig(false)}
				/>
			) : null}
		</ui.Relative>
	)
}

export default TrainerMenu
