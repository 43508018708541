import { forEach } from 'lodash'
import {
	AssignMode,
	FeaturesChangedEvent,
	FileId,
	GroupAddress,
	GroupId,
	GroupPartitionMap,
	GroupReview,
	GroupState,
	GroupSuggestionAssignmentDto,
	HandPosition,
	InteractionSchemeAddress,
	InteractionSchemeKey,
	InteractionSchemeRoomTransferWithCountdownEvent,
	InteractionSchemeStateChangeEvent,
	PartitionMode,
	QuizAnswerId,
	QuizQuestionAnswered,
	QuizQuestionAsked,
	QuizQuestionCompleted,
	RoomAddress,
	RoomId,
	RoomKey,
	TrainerUnavailableReason,
	TrainingPhase,
	TrainingRoomPhase,
	User,
	UserId,
	UserKey,
	UserRole,
	VcrId,
	WorkflowEvent,
	WorkflowId,
	WorkflowKey,
} from 'common/types'
import { Config } from 'common/config'
import { fetchReliably as fetch } from 'connectivity/reliable-fetch'
import { PublicChatRoomType } from 'room/lobby'
import { checkStatus, parseBody } from 'utils/http'
import { jsonEventHandler } from 'utils/event-source'
import { v4 as uuidv4 } from 'uuid'
import { Grouping } from 'room/training-room/set-groups/types'

type WorkflowEventSourceArgs = {
	workflowId: string
	onWorkflowEvent(event: WorkflowEvent): void
}

type GroupSuggestionWithGroupsCountArgs = RoomAddress & {
	maxGroupSize: number
	partitionMode: PartitionMode
	assignMode: AssignMode
	taskName: string
}

type GroupSuggestionWithoutGroupsCountArgs = RoomAddress & {
	partitionMode: PartitionMode
	assignMode: AssignMode
	taskName: string
}

type GroupSuggestionAssigmentArgs = RoomAddress & {
	userId: string
	groupId: GroupId | undefined
	final: boolean
	userRole: UserRole
}

type ChangeAttentionNeedStatusArgs = GroupAddress & {
	handPosition: HandPosition
}

export type PartitionEvent = {
	partitionMap: GroupPartitionMap
}

export type TrainingRoomPhaseChangedEvent = {
	phase: TrainingRoomPhase
}

export type TrainingRoomPollingStartedEvent = {
	id: string
}

export type TrainingRoomPollingReviewStartedEvent = {
	id: string
}

export type HandPositionChangedEvent = {
	groupId: string
	position: HandPosition
}

export type TrainerBecameUnavailableEvent = {
	reason: TrainerUnavailableReason
	recipientGroupIds: string[]
}

export type TrainerBecameAvailableEvent = {
	recipientGroupIds: string[]
}

export type TrainerIsJoiningGroupEvent = {
	recipientGroupId: string
	countdown: number
}

export type GroupStateChangedEvent = {
	groups: GroupState[]
}

export type GroupsInitialStateEvents = {
	initialEvents: (
		| (HandPositionChangedEvent & { eventType: 'HAND_POSITION_CHANGED' })
		| (TrainerBecameUnavailableEvent & {
				eventType: 'TRAINER_BECAME_UNAVAILABLE'
		  })
		| (GroupStateChangedEvent & { eventType: 'GROUPS_STATE_CHANGED' })
		| (TrainerStatusChangedEvent & { eventType: 'TRAINER_STATUS_CHANGED' })
		| (ExcalidrawOpenedEvent & { eventType: 'EXCALIDRAW_OPENED' })
		| (EtherPadOpenedEvent & { eventType: 'ETHER_PAD_OPENED' })
	)[]
}

export type TrainerInGroup = {
	trainerStatus: 'TRAINER_IN_GROUP'
	groupId: string
}
export type TrainerBeRightBack = { trainerStatus: 'TRAINER_BE_RIGHT_BACK' }
export type TrainerLooksAtGroupsView = {
	trainerStatus: 'TRAINER_LOOKS_AT_GROUPS_VIEW'
}

export type TrainerStatusChangedEvent =
	| TrainerInGroup
	| TrainerBeRightBack
	| TrainerLooksAtGroupsView

export type RoomStateChangedEvent = {
	id: RoomId
	state: {
		users: User[]
	}
}

export type GroupSuggestionStateChangedEvent = {
	groups: GroupSuggestionAssignmentDto[]
}

export type EtherPadOpenedEvent = {
	groupId: GroupId
}

export type EtherPadClosedEvent = {
	groupId: GroupId
}

export type EtherPadGroupSwitchedEvent = {
	groupId: GroupId
	previousGroup: GroupId
}

export type ExcalidrawOpenedEvent = {
	groupId: GroupId
}

export type ExcalidrawClosedEvent = {
	groupId: GroupId
}

export type ExcalidrawGroupSwitchedEvent = {
	groupId: GroupId
	previousGroup: GroupId
}

export type GroupPropertiesChangedEvent = {
	id: string
	name: string
}

export type ReviewActiveGroupChangedEvent = {
	id: GroupId
}

export type PlayerScore = UserKey & {
	name: string
	score: number
}

export type PlacedScore = {
	score: number
	players: (UserKey & { name: string })[]
}

export type QuizConcludedEvent = {
	quizId: string
	thereAreNoWinners: boolean
	totalQuestions: number
	firstPlace: PlacedScore
	secondPlace: PlacedScore
	thirdPlace: PlacedScore
	players: PlayerScore[]
}

export type PlayersChangedEvent = {
	expectedNumberOfAnswers: number
}

export type TrainingRoomInitialStateEvent = {
	initialEvents: (
		| InteractionSchemeEvents
		| ({ eventType: 'ACTIVE_GROUP_CHANGED' } & ReviewActiveGroupChangedEvent)
		| ({ eventType: 'ETHER_PAD_OPENED' } & EtherPadOpenedEvent)
		| ({ eventType: 'ETHER_PAD_GROUP_SWITCHED' } & EtherPadGroupSwitchedEvent)
		| ({ eventType: 'EXCALIDRAW_OPENED' } & ExcalidrawOpenedEvent)
		| ({
				eventType: 'EXCALIDRAW_GROUP_SWITCHED'
		  } & ExcalidrawGroupSwitchedEvent)
		| ({ eventType: 'QUESTION_ASKED' } & QuizQuestionAsked)
		| ({ eventType: 'QUESTION_ANSWERED' } & QuizQuestionAnswered)
		| ({ eventType: 'QUESTION_COMPLETED' } & QuizQuestionCompleted)
		| ({ eventType: 'QUIZ_CONCLUDED' } & QuizConcludedEvent)
		| ({
				eventType: 'GROUPS_SUGGESTION_STATE_CHANGED'
		  } & GroupSuggestionStateChangedEvent)
		| ({ eventType: 'GROUPS_PARTITION_MAP' } & PartitionEvent)
		| ({ eventType: 'ROOM_PHASE_CHANGED' } & TrainingRoomPhaseChangedEvent)
		| ({ eventType: 'POLLING_STARTED' } & TrainingRoomPollingStartedEvent)
		| ({
				eventType: 'POLLING_REVIEW_STARTED'
		  } & TrainingRoomPollingReviewStartedEvent)
	)[]
}

export type AgendaUpdatedEvent = {
	timezone: string
	days: Record<string, { title: string; time: string; icon: string }[]>
}

export type LobbyInitialStateEvent = {
	initialEvents: unknown[]
}

type LobbyEventSourceArgs = WorkflowKey &
	InteractionSchemeKey &
	RoomKey & {
		onEvent(event: unknown): void
		onInteractionSchemeStateChange(
			event: InteractionSchemeStateChangeEvent
		): void
		onInteractionSchemeRoomTransferWithCountdown(
			event: InteractionSchemeRoomTransferWithCountdownEvent
		): void
		onWorkflowEvent(event: WorkflowEvent): void
		onInitialStateEvent(event: LobbyInitialStateEvent): void
		onRoomStateChangedEvent(event: RoomStateChangedEvent): void
	}

type PublicChatEventSourceArgs = WorkflowKey &
	InteractionSchemeKey &
	RoomKey & {
		onEvent(event: unknown): void
		onInitialStateEvent(event: LobbyInitialStateEvent): void
		onInteractionSchemeStateChange(
			event: InteractionSchemeStateChangeEvent
		): void
		onInteractionSchemeRoomTransferWithCountdown(
			event: InteractionSchemeRoomTransferWithCountdownEvent
		): void
		onWorkflowEvent(event: WorkflowEvent): void
		onRoomStateChangedEvent(event: RoomStateChangedEvent): void
	}

type TrainingRoomEventSourceArgs = WorkflowKey &
	InteractionSchemeKey &
	RoomKey & {
		onEvent(event: unknown): void
		onInteractionSchemeStateChange(
			event: InteractionSchemeStateChangeEvent
		): void
		onInteractionSchemeRoomTransferWithCountdown(
			event: InteractionSchemeRoomTransferWithCountdownEvent
		): void
		onWorkflowEvent(event: WorkflowEvent): void
		onInitialStateEvent(event: TrainingRoomInitialStateEvent): void
		onRoomStateChangedEvent(event: RoomStateChangedEvent): void
		onGroupSuggestionStateChangedEvent(
			event: GroupSuggestionStateChangedEvent
		): void
		onTrainingRoomGroupPartitionEvent(event: PartitionEvent): void
		onTrainingRoomPhaseChangedEvent(event: TrainingRoomPhaseChangedEvent): void
		onReviewActiveGroupChangedEvent(event: ReviewActiveGroupChangedEvent): void
		onEtherPadOpened(event: EtherPadOpenedEvent): void
		onEtherPadClosed(event: EtherPadClosedEvent): void
		onEtherPadGroupSwitched(event: EtherPadGroupSwitchedEvent): void
		onExcalidrawOpened(event: ExcalidrawOpenedEvent): void
		onExcalidrawClosed(event: ExcalidrawClosedEvent): void
		onExcalidrawGroupSwitched(event: ExcalidrawGroupSwitchedEvent): void
		onQuestionAsked(event: QuizQuestionAsked): void
		onQuestionAnswered(event: QuizQuestionAnswered): void
		onQuestionCompleted(event: QuizQuestionCompleted): void
		onQuizConcluded(event: QuizConcludedEvent): void
		onPlayersChanged(event: PlayersChangedEvent): void
	}

type GroupRoomEventSourceArgs = WorkflowKey &
	InteractionSchemeKey &
	RoomKey & {
		onEvent(event: unknown): void
		onInteractionInitialState: (
			event: InteractionSchemeInitialStateEvent
		) => void
		onInteractionSchemeStateChange(
			event: InteractionSchemeStateChangeEvent
		): void
		onInteractionSchemeRoomTransferWithCountdown(
			event: InteractionSchemeRoomTransferWithCountdownEvent
		): void
		onWorkflowEvent(event: WorkflowEvent): void
		onGroupsInitialStateEvent(event: GroupsInitialStateEvents): void
		onHandPositionChangedEvent(event: HandPositionChangedEvent): void
		onTrainerBecameUnavailableEvent(event: TrainerBecameUnavailableEvent): void
		onTrainerIsJoiningGroupEvent(event: TrainerIsJoiningGroupEvent): void
		onGroupStateChangedEvent(event: GroupStateChangedEvent): void
		onTrainerStatusChangedEvent(event: TrainerStatusChangedEvent): void
		onTrainerBecameAvailableEvent(event: TrainerBecameAvailableEvent): void
		onRoomStateChangedEvent(event: RoomStateChangedEvent): void
		onEtherPadOpened(event: EtherPadOpenedEvent): void
		onEtherPadClosed(event: EtherPadClosedEvent): void
		onExcalidrawOpened(event: ExcalidrawOpenedEvent): void
		onExcalidrawClosed(event: ExcalidrawClosedEvent): void
		onGroupPropertiesChanged(event: GroupPropertiesChangedEvent): void
	}

type InteractionSchemeEvents = FeaturesChangedEvent
type InteractionSchemeInitialStateEvent = {
	initialEvents: Array<InteractionSchemeEvents>
}
type InteractionSchemeEventSourceArgs = WorkflowKey &
	InteractionSchemeKey & {
		onInitialStateEvents(event: InteractionSchemeInitialStateEvent): void
		onInteractionSchemeStateChange(
			event: InteractionSchemeStateChangeEvent
		): void
		onInteractionSchemeRoomTransferWithCountdown(
			event: InteractionSchemeRoomTransferWithCountdownEvent
		): void
		onWorkflowEvent(event: WorkflowEvent): void
	}

type JoinWorkflowArgs = {
	workflowId: string
}

type LeaveWorkflowArgs = {
	workflowId: string
	userId: string
}

export type EtherpadSessionDto = {
	padId: string
	sessionId: string
}

export type ExcalidrawSessionDto = {
	boardId: { value: string }
	role: 'VIEWER' | 'CREATOR'
}

export type CurrentPublicChatRoom = {
	id: RoomId
	type: PublicChatRoomType
	hobby?: string
}

function getErrorMessageFromGroupSuggestionConfirmResponseBody(
	result: Response
) {
	switch (result.status) {
		case 204:
			return null
		case 400:
			return result.json()
		default:
			throw new Error(`Post groups suggestion status ${result.status}`)
	}
}

const registerGlobalDispatch = (
	eventSource: EventSource,
	events: string[],
	onEvent: (event: unknown) => void
) => {
	forEach(events, e =>
		eventSource.addEventListener(e, jsonEventHandler(onEvent))
	)
}

function addWorkflowEventsListener(
	eventSource: EventSource,
	onWorkflowEvent: (event: WorkflowEvent) => void
) {
	eventSource.addEventListener(
		'WORKFLOW_STATE_CHANGED',
		jsonEventHandler(onWorkflowEvent)
	)
}

function addInteractionSchemeEventsListeners(
	eventSource: EventSource,
	onInitialStateEvents: (event: InteractionSchemeInitialStateEvent) => void,
	onInteractionSchemeStateChange: (
		event: InteractionSchemeStateChangeEvent
	) => void,
	onInteractionSchemeRoomTransferWithCountdown: (
		event: InteractionSchemeRoomTransferWithCountdownEvent
	) => void
) {
	eventSource.addEventListener(
		'IS_STATE_CHANGED',
		jsonEventHandler(onInteractionSchemeStateChange)
	)
	eventSource.addEventListener(
		'IS_ROOM_TRANSFER_WITH_COUNTDOWN',
		jsonEventHandler(onInteractionSchemeRoomTransferWithCountdown)
	)
	eventSource.addEventListener(
		'INITIAL_STATE',
		jsonEventHandler(onInitialStateEvents)
	)
}

export type VcrIdDto = {
	vcrId: VcrId
}

export type QuizAnswerProps = RoomAddress & {
	answerId: QuizAnswerId
}

const INTERACTION_SCHEME_LEVEL_EVENTS = [
	'SCRIPT_STATE_CHANGED',
	'FEATURE_FOCUSED',
	'FEATURES_CHANGED',
]

const LOBBY_EVENTS = [
	...INTERACTION_SCHEME_LEVEL_EVENTS,
	'PUBLIC_CHAT_ROOM_STATUS_CHANGED',
	'PUBLIC_CHAT_ROOM_ENCOURAGEMENT_CHANGED',
	'USERS_DISCOURAGED_TO_VISIT_ANY_PUBLIC_CHAT_ROOM',
	'ROSTERS_LIST_STATE_CHANGED',
]

const CHAT_ROOM_EVENTS = [
	...INTERACTION_SCHEME_LEVEL_EVENTS,
	'ROSTERS_LIST_STATE_CHANGED',
	'CONFERENCE_CALL_EXPECTED',
	'MICROPHONE_MUTED',
	'MICROPHONE_UNMUTED',
	'CAMERA_MUTED',
	'CAMERA_UNMUTED',
]

const TRAINING_ROOM_EVENTS = [
	...INTERACTION_SCHEME_LEVEL_EVENTS,
	'SIMPLE_QUESTION_STATE_CHANGED',
	'PERMISSION_MODE_CHANGED',
	'SIMPLE_QUESTION_FINISHED',
	'KNOWLEDGE_CHECK_DEFINITIONS_AVAILABILITY',
	'SIMPLE_GROUPING_STATE_CHANGED',
	'GROUPING_DEFINITIONS_AVAILABILITY',
	'SLIDE_DEFINITIONS_AVAILABILITY',
	'SLIDE_STARTED',
	'SLIDE_STOPPED',
	'KNOWLEDGE_CHECK_STARTED',
	'KNOWLEDGE_CHECK_REVIEW_STARTED',
	'KNOWLEDGE_CHECK_ANSWERED',
	'PARTICIPANT_JOINED_POLLING',
	'PARTICIPANT_FINISHED_POLLING',
	'KNOWLEDGE_CHECK_CLOSED',
	'KNOWLEDGE_CHECK_PREVIOUS_QUESTION',
	'KNOWLEDGE_CHECK_NEXT_QUESTION',
	'KNOWLEDGE_CHECK_REVIEW_NEXT_QUESTION',
	'KNOWLEDGE_CHECK_REVIEW_PREVIOUS_QUESTION',
	'KNOWLEDGE_CHECK_REVIEW_PREVIOUS_QUESTION',
	'PARTICIPANT_FINISHED_KNOWLEDGE_CHECK',
	'CONFERENCE_CALL_EXPECTED',
	'MICROPHONE_MUTED',
	'MICROPHONE_UNMUTED',
	'CAMERA_MUTED',
	'CAMERA_UNMUTED',
	'SCREEN_SHARING_STARTED',
	'SCREEN_SHARING_STOPPED',
	'WORD_CLOUD_POLLING_DEFINITIONS_AVAILABILITY',
	'WORD_CLOUD_POLLING_STARTED',
	'WORD_CLOUD_POLLING_ANSWERS',
	'WORD_CLOUD_POLLING_ANSWERERS_STATS_CHANGED',
	'LIST_POLLING_DEFINITIONS_AVAILABILITY',
	'LIST_POLLING_STARTED',
	'LIST_POLLING_ANSWERS',
	'LIST_POLLING_ANSWERERS_STATS_CHANGED',
	'MULTIPLE_CHOICE_POLLING_DEFINITIONS_AVAILABILITY',
	'MULTIPLE_CHOICE_POLLING_STARTED',
	'MULTIPLE_CHOICE_POLLING_ANSWERS',
	'MULTIPLE_CHOICE_POLLING_ANSWERERS_STATS_CHANGED',
	'NOTEPAD_CLOSED',
	'NOTEPAD_OPENED',
	'NOTEPAD_COLLABORATION_STATE_CHANGED',
	'NOTEPADS_STATE_CHANGED',
	'WHITEBOARD_OPENED',
	'WHITEBOARD_CLOSED',
	'WHITEBOARD_COLLABORATION_STATE_CHANGED',
	'WHITEBOARDS_STATE_CHANGED',
	'WORKFLOW_CONTEXT_STOPPED',
	'AUDIO_STREAM_CHOSEN',
	'AUDIO_STREAM_REMOVED',
	'AUDIO_STREAM_OFFSET_REQUESTED',
	'AUDIO_STREAM_OFFSET_RECEIVED',
	'AUDIO_STREAMING_STARTED',
	'AUDIO_STREAMING_PAUSED',
	'AUDIO_STREAMING_FINISHED',
]

export default class BeeldayClient {
	joinWorkflow = async (props: JoinWorkflowArgs) => {
		await fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/users`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	heartBeat = (
		workflowId: WorkflowId,
		userId: UserId,
		secondsSinceLastActivity: number
	) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${workflowId}/users/${userId}/heartbeat`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({ secondsSinceLastActivity }),
			}
		)
	}

	leaveWorkflow = (props: LeaveWorkflowArgs) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/users/${props.userId}`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	startTraining = (props: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/start`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	startQuiz = (props: RoomAddress & { quizId?: string }) => {
		const quizId = props.quizId ?? props.roomId
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/features/quiz/${quizId}`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	finishQuiz = (props: RoomAddress & { quizId?: string }) => {
		const quizId = props.quizId ?? props.roomId
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/features/quiz/${quizId}/finished`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	concludeQuiz = (props: RoomAddress & { quizId?: string }) => {
		const quizId = props.quizId ?? props.roomId
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/features/quiz/${quizId}/concluded`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	nextQuizQuestion = (props: RoomAddress & { quizId?: string }) => {
		const quizId = props.quizId ?? props.roomId
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/features/quiz/${quizId}/question`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	finishQuizQuestion = (props: RoomAddress & { quizId?: string }) => {
		const quizId = props.quizId ?? props.roomId
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/features/quiz/${quizId}/question`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	confirmQuizAnswer = (props: QuizAnswerProps & { quizId?: string }) => {
		const quizId = props.quizId ?? props.roomId
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/features/quiz/${quizId}/question/answers/${props.answerId}/selected`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	groupSuggestionWithMaxGroupSize = (
		props: GroupSuggestionWithGroupsCountArgs
	) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/trainingRoom/${props.roomId}/groupssuggestion`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({
					maxGroupSize: props.maxGroupSize,
					partitionMode: props.partitionMode,
					assignMode: props.assignMode,
					task: {
						name: props.taskName,
					},
				}),
			}
		).then(checkStatus)
	}

	groupSuggestionWithoutMaxGroupSize = (
		props: GroupSuggestionWithoutGroupsCountArgs
	) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/trainingRoom/${props.roomId}/groupssuggestion`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({
					partitionMode: props.partitionMode,
					assignMode: props.assignMode,
					task: {
						name: props.taskName,
					},
				}),
			}
		).then(checkStatus)
	}

	groupSuggestionAssigment = (props: GroupSuggestionAssigmentArgs) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/trainingRoom/${props.roomId}/groupssuggestion/assignment`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
					Role: props.userRole,
				},
				body: JSON.stringify({
					userId: props.userId,
					groupId: props.groupId,
					final: props.final,
				}),
			}
		).then(checkStatus)
	}

	assignUserToGroup = (props: Omit<GroupSuggestionAssigmentArgs, 'final'>) =>
		this.groupSuggestionAssigment({ ...props, final: false })
	finalAssignUserToGroup = (
		props: Omit<GroupSuggestionAssigmentArgs, 'final'>
	) => this.groupSuggestionAssigment({ ...props, final: true })

	confirmSuggestionAssigment = (props: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/trainingRoom/${props.roomId}/groupssuggestion/assignment/confirmation`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(result => {
			return getErrorMessageFromGroupSuggestionConfirmResponseBody(result)
		})
	}

	cancelGroupSuggestion = (props: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/features/grouping/cancel`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify(true),
			}
		).then(checkStatus)
	}

	changeHandPosition = (props: ChangeAttentionNeedStatusArgs) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/groupRoom/${props.roomId}/groups/${props.groupId}/hand`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': `text/plain`,
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: props.handPosition,
			}
		).then(checkStatus)
	}

	raiseHand = (props: GroupAddress) =>
		this.changeHandPosition({ ...props, handPosition: HandPosition.UP })
	lowerHand = (props: GroupAddress) =>
		this.changeHandPosition({ ...props, handPosition: HandPosition.DOWN })

	trainerJoinsGroup = (props: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/groupRoom/${props.roomId}/groups/${props.groupId}/trainer`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	trainerLeavesGroup = (props: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/groupRoom/${props.roomId}/groups/${props.groupId}/trainer`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	trainerCameBack = (props: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/groupRoom/${props.roomId}/groups/trainer/beRightBack`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	trainerAnnouncedToBeRightBack = (props: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/groupRoom/${props.roomId}/groups/trainer/beRightBack`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	reviewTask = (props: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/groupRoom/${props.roomId}/tasks`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({ command: 'REVIEW' }),
			}
		).then(checkStatus)
	}

	completeReview = (props: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/rooms/trainingRoom/${props.roomId}/review/completed`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: 'true',
			}
		).then(checkStatus)
	}

	changeGroupName = (groupAddress: GroupAddress, name: string) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${groupAddress.workflowId}/interactionschemes/training/${groupAddress.interactionSchemeId}/rooms/groupRoom/${groupAddress.roomId}/groups/${groupAddress.groupId}/name`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: name,
			}
		).then(checkStatus)
	}

	interactionSchemeEventSource = (props: InteractionSchemeEventSourceArgs) => {
		const eventSource = new EventSource(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/training/${props.interactionSchemeId}/events`,
			{
				withCredentials: true,
			}
		)
		addWorkflowEventsListener(eventSource, props.onWorkflowEvent)
		addInteractionSchemeEventsListeners(
			eventSource,
			props.onInitialStateEvents,
			props.onInteractionSchemeStateChange,
			props.onInteractionSchemeRoomTransferWithCountdown
		)
		return eventSource
	}

	workflowEventSource = (props: WorkflowEventSourceArgs) => {
		const eventSource = new EventSource(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/events`,
			{
				withCredentials: true,
			}
		)
		addWorkflowEventsListener(eventSource, props.onWorkflowEvent)
		return eventSource
	}

	lobbyEventSource = (props: LobbyEventSourceArgs) => {
		const eventSource = new EventSource(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/events`,
			{
				withCredentials: true,
			}
		)
		addWorkflowEventsListener(eventSource, props.onWorkflowEvent)
		addInteractionSchemeEventsListeners(
			eventSource,
			props.onInitialStateEvent,
			props.onInteractionSchemeStateChange,
			props.onInteractionSchemeRoomTransferWithCountdown
		)
		registerGlobalDispatch(eventSource, LOBBY_EVENTS, props.onEvent)
		eventSource.addEventListener(
			'INITIAL_STATE',
			jsonEventHandler(props.onInitialStateEvent)
		)
		eventSource.addEventListener(
			'ROOM_STATE_CHANGED',
			jsonEventHandler(props.onRoomStateChangedEvent)
		)
		return eventSource
	}

	publicChatEventSource = (args: PublicChatEventSourceArgs) => {
		const eventSource = new EventSource(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${args.workflowId}/interactionschemes/${args.interactionSchemeId}/rooms/${args.roomId}/events`,
			{
				withCredentials: true,
			}
		)
		addWorkflowEventsListener(eventSource, args.onWorkflowEvent)
		addInteractionSchemeEventsListeners(
			eventSource,
			args.onInitialStateEvent,
			args.onInteractionSchemeStateChange,
			args.onInteractionSchemeRoomTransferWithCountdown
		)
		eventSource.addEventListener(
			'INITIAL_STATE',
			jsonEventHandler(args.onInitialStateEvent)
		)
		eventSource.addEventListener(
			'ROOM_STATE_CHANGED',
			jsonEventHandler(args.onRoomStateChangedEvent)
		)
		registerGlobalDispatch(eventSource, CHAT_ROOM_EVENTS, args.onEvent)
		return eventSource
	}

	trainingRoomEventSource = (props: TrainingRoomEventSourceArgs) => {
		const eventSource = new EventSource(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/events`,
			{
				withCredentials: true,
			}
		)
		addWorkflowEventsListener(eventSource, props.onWorkflowEvent)
		addInteractionSchemeEventsListeners(
			eventSource,
			props.onInitialStateEvent,
			props.onInteractionSchemeStateChange,
			props.onInteractionSchemeRoomTransferWithCountdown
		)
		registerGlobalDispatch(eventSource, TRAINING_ROOM_EVENTS, props.onEvent)
		eventSource.addEventListener(
			'GROUPS_SUGGESTION_STATE_CHANGED',
			jsonEventHandler(props.onGroupSuggestionStateChangedEvent)
		)
		eventSource.addEventListener(
			'ROOM_STATE_CHANGED',
			jsonEventHandler(props.onRoomStateChangedEvent)
		)
		eventSource.addEventListener(
			'GROUPS_PARTITION_MAP',
			jsonEventHandler(props.onTrainingRoomGroupPartitionEvent)
		)
		eventSource.addEventListener(
			'ROOM_PHASE_CHANGED',
			jsonEventHandler(props.onTrainingRoomPhaseChangedEvent)
		)
		eventSource.addEventListener(
			'ACTIVE_GROUP_CHANGED',
			jsonEventHandler(props.onReviewActiveGroupChangedEvent)
		)
		eventSource.addEventListener(
			'INITIAL_STATE',
			jsonEventHandler(props.onInitialStateEvent)
		)
		eventSource.addEventListener(
			'ETHER_PAD_OPENED',
			jsonEventHandler(props.onEtherPadOpened)
		)
		eventSource.addEventListener(
			'ETHER_PAD_CLOSED',
			jsonEventHandler(props.onEtherPadClosed)
		)
		eventSource.addEventListener(
			'EXCALIDRAW_OPENED',
			jsonEventHandler(props.onExcalidrawOpened)
		)
		eventSource.addEventListener(
			'EXCALIDRAW_CLOSED',
			jsonEventHandler(props.onExcalidrawClosed)
		)
		eventSource.addEventListener(
			'ETHER_PAD_GROUP_SWITCHED',
			jsonEventHandler(props.onEtherPadGroupSwitched)
		)
		eventSource.addEventListener(
			'QUESTION_ASKED',
			jsonEventHandler(props.onQuestionAsked)
		)
		eventSource.addEventListener(
			'QUESTION_ANSWERED',
			jsonEventHandler(props.onQuestionAnswered)
		)
		eventSource.addEventListener(
			'QUESTION_COMPLETED',
			jsonEventHandler(props.onQuestionCompleted)
		)
		eventSource.addEventListener(
			'QUIZ_CONCLUDED',
			jsonEventHandler(props.onQuizConcluded)
		)
		eventSource.addEventListener(
			'PLAYERS_CHANGED',
			jsonEventHandler(props.onPlayersChanged)
		)
		return eventSource
	}

	groupRoomEventSource = (props: GroupRoomEventSourceArgs) => {
		const eventSource = new EventSource(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${props.workflowId}/interactionschemes/${props.interactionSchemeId}/rooms/${props.roomId}/events`,
			{
				withCredentials: true,
			}
		)
		addWorkflowEventsListener(eventSource, props.onWorkflowEvent)
		addInteractionSchemeEventsListeners(
			eventSource,
			props.onInteractionInitialState,
			props.onInteractionSchemeStateChange,
			props.onInteractionSchemeRoomTransferWithCountdown
		)
		registerGlobalDispatch(eventSource, TRAINING_ROOM_EVENTS, props.onEvent)
		eventSource.addEventListener(
			'ROOM_STATE_CHANGED',
			jsonEventHandler(props.onRoomStateChangedEvent)
		)
		eventSource.addEventListener(
			'HAND_POSITION_CHANGED',
			jsonEventHandler(props.onHandPositionChangedEvent)
		)
		eventSource.addEventListener(
			'TRAINER_BECAME_UNAVAILABLE',
			jsonEventHandler(props.onTrainerBecameUnavailableEvent)
		)
		eventSource.addEventListener(
			'TRAINER_BECAME_AVAILABLE',
			jsonEventHandler(props.onTrainerBecameAvailableEvent)
		)
		eventSource.addEventListener(
			'TRAINER_IS_JOINING_GROUP',
			jsonEventHandler(props.onTrainerIsJoiningGroupEvent)
		)
		eventSource.addEventListener(
			'GROUPS_STATE_CHANGED',
			jsonEventHandler(props.onGroupStateChangedEvent)
		)
		eventSource.addEventListener(
			'TRAINER_STATUS_CHANGED',
			jsonEventHandler(props.onTrainerStatusChangedEvent)
		)
		eventSource.addEventListener(
			'INITIAL_STATE',
			jsonEventHandler(props.onGroupsInitialStateEvent)
		)
		eventSource.addEventListener(
			'GROUP_PROPERTIES_CHANGED',
			jsonEventHandler(props.onGroupPropertiesChanged)
		)
		eventSource.addEventListener(
			'ETHER_PAD_OPENED',
			jsonEventHandler(props.onEtherPadOpened)
		)
		eventSource.addEventListener(
			'ETHER_PAD_CLOSED',
			jsonEventHandler(props.onEtherPadClosed)
		)
		eventSource.addEventListener(
			'EXCALIDRAW_OPENED',
			jsonEventHandler(props.onExcalidrawOpened)
		)
		eventSource.addEventListener(
			'EXCALIDRAW_CLOSED',
			jsonEventHandler(props.onExcalidrawClosed)
		)
		return eventSource
	}

	getVcr = (address: RoomAddress): Promise<VcrIdDto> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/vcr`,
			{
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	restart() {
		return fetch(`${Config.beeldayBackendUrl}/actuator/restart`, {
			method: 'POST',
			headers: {
				'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
			},
		})
			.then(checkStatus)
			.then(parseBody)
	}

	getEtherPadSession = (address: GroupAddress): Promise<EtherpadSessionDto> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/groups/${address.groupId}/etherPad/session`,
			{
				method: 'POST',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	getExcalidrawSession = (
		address: GroupAddress
	): Promise<ExcalidrawSessionDto> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/groups/${address.groupId}/excalidraw/session`,
			{
				method: 'GET',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	getExcalidrawSessionForReview = (
		address: RoomAddress
	): Promise<ExcalidrawSessionDto> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/review/excalidraw/session`,
			{
				method: 'GET',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	getGroup = (address: GroupAddress): Promise<GroupReview> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/groups/${address.groupId}`,
			{
				method: 'GET',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	getGroupingDefinitions = (address: RoomAddress): Promise<Grouping[]> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/grouping-definitions`,
			{
				method: 'GET',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	createGroupingFromTemplate = (address: RoomAddress, props: Grouping) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/grouping`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({
					name: props.name,
					description: props.description,
					groupSize: props.groupSize,
					groupingType: props.groupingType,
				}),
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	openEtherPad = (address: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/groups/${address.groupId}/etherPad/opened`,
			{
				method: 'PUT',
				body: 'true',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	closeEtherPad = (address: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/groups/${address.groupId}/etherPad/opened`,
			{
				method: 'PUT',
				body: 'false',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	openExcalidraw = (address: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/groups/${address.groupId}/excalidraw/opened`,
			{
				method: 'PUT',
				body: 'true',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	closeExcalidrawForReview = (address: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/review/excalidraw/opened`,
			{
				method: 'PUT',
				body: 'false',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	openExcalidrawForReview = (address: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/review/excalidraw/opened`,
			{
				method: 'PUT',
				body: 'true',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	closeExcalidraw = (address: GroupAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/groups/${address.groupId}/excalidraw/opened`,
			{
				method: 'PUT',
				body: 'false',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	getEtherPadDataForReview = (
		address: RoomAddress
	): Promise<EtherpadSessionDto> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/review/etherPad/session`,
			{
				method: 'POST',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	openEtherPadForReview = (address: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/review/etherPad/opened`,
			{
				method: 'PUT',
				body: 'true',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	closeEtherPadForReview = (address: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/${address.roomType}/${address.roomId}/review/etherPad/opened`,
			{
				method: 'PUT',
				body: 'false',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
	}

	getGroupsForReview = (address: RoomAddress): Promise<GroupReview[]> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/trainingRoom/${address.roomId}/groups`,
			{
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	reviewNextGroup = (address: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/trainingRoom/${address.roomId}/review/next`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	reviewPrevGroup = (address: RoomAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/trainingRoom/${address.roomId}/review/previous`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	reviewSelect = (address: RoomAddress, groupId: GroupId) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/trainingRoom/${address.roomId}/review/groups/${groupId}`,
			{
				method: 'PUT',
				headers: {
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(checkStatus)
	}

	getPublicChatRooms = (
		address: RoomAddress
	): Promise<CurrentPublicChatRoom[]> => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/rooms/lobby/${address.roomId}/publicChatRooms`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)
	}

	transfer = (address: RoomAddress, to: RoomId): Promise<void | Response> =>
		fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/transfers`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({
					from: address.roomId,
					to,
				}),
			}
		).then(checkStatus)

	private _break = (flag: boolean) => (address: InteractionSchemeAddress) => {
		return fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/break/active`,
			{
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': '0',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify(flag),
			}
		).then(checkStatus)
	}

	startBreak = this._break(true)
	endBreak = this._break(false)

	getTrainingPhase = (
		address: InteractionSchemeAddress
	): Promise<TrainingPhase> =>
		fetch(
			`${Config.beeldayBackendUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/training/${address.interactionSchemeId}/phase`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(checkStatus)
			.then(parseBody)

	quizImgUrl = (fileId: FileId): string => {
		return `${Config.assetsUrl}/quiz/${fileId}.jpg`
	}
}
