import { AnyAction, configureStore, Reducer } from '@reduxjs/toolkit'
import { connectivityReducer, ConnectivityState } from 'connectivity/redux'
import {
	eventSourceReducer,
	EventSourceState,
} from 'event-source-manager/redux'
import { knowledgeChecksReducer } from 'features/knowledge-checks'
import { questionsReducer } from 'features/questions'
import { screenShareReducer } from 'features/screen-share'
import { redux as scriptRedux } from 'features/script'
import { redux as slidesRedux } from 'features/slides'
import { redux as whiteboardsRedux } from 'features/whiteboard'
import { redux as notepadsRedux } from 'features/notepad'
import { groupEngagementReducer } from 'features/user-engagement'
import {
	redux as wordCloudRedux,
	WordCloudState,
} from 'features/pollings/word-cloud'
import {
	redux as multipleChoicePollingRedux,
	MultipleChoicePollingState,
} from 'features/pollings/multiple-choice-polling'
import {
	redux as listPollingRedux,
	ListPollingState,
} from 'features/pollings/list-polling'
import { interactionSchemeReducer } from 'interaction-scheme/redux'
import { roomReducer } from 'room/common/redux'
import { groupRoomReducer, GroupRoomState } from 'room/group-room/redux'
import { reducer as lobbyReducer } from 'room/lobby/redux'
import { rosterReducer, RosterState } from 'room/roster/redux'
import {
	trainingRoomReducer,
	TrainingRoomState,
} from 'room/training-room/redux'
import {
	middleware as sessionInfoMiddleware,
	reducer as sessionInfoRedux,
} from 'session-info/redux'
import { reducer as sessionReducer, SessionState } from 'session/redux'
import { usersReducer } from 'users'
import { middleware as usersMiddleware, UsersState } from 'users/redux'
import { videoConferenceMediaReducer } from 'video-conference-media/redux'
import { workflowReducer, WorkflowsState } from 'workflow/redux'
import { ScreenShareState } from '../features/screen-share/screen-share-redux'
import {
	OrganizationState,
	organizationSlice as organizationReducer,
} from 'organization/redux'

import {
	redux as audioStreamingRedux,
	AudioStreamingState,
} from 'features/audio-streaming/audio-streaming-redux'

import {
	State as SimpleGroupingState,
	reducer as simpleGroupingReducer,
} from 'features/simple-grouping/simple-grouping-redux'

/**
 * All this casting here (as Reducer<>) is needed
 * because the redux-toolkit has some issues when
 * reducer action types don't include action
 * without payload. Maybe they will fix it in the future.
 *
 * It should not break any type checking, please just don't
 * use AnyAction anywhere else.
 */
export const rootStore = configureStore({
	reducer: {
		workflow: workflowReducer as Reducer<WorkflowsState, AnyAction>,
		interactionScheme: interactionSchemeReducer,
		lobby: lobbyReducer,
		roster: rosterReducer as Reducer<RosterState, AnyAction>,
		room: roomReducer,
		trainingRoom: trainingRoomReducer as Reducer<TrainingRoomState, AnyAction>,
		groupRoom: groupRoomReducer as Reducer<GroupRoomState, AnyAction>,
		simpleGrouping: simpleGroupingReducer as Reducer<
			SimpleGroupingState,
			AnyAction
		>,
		sessionInfo: sessionInfoRedux,
		// sessionInfoReducer as Reducer<SessionPlanningState, AnyAction>,
		videoConferenceMedia: videoConferenceMediaReducer,
		eventSource: eventSourceReducer as Reducer<EventSourceState, AnyAction>,
		connectivity: connectivityReducer as Reducer<ConnectivityState, AnyAction>,
		questions: questionsReducer,
		screenShare: screenShareReducer as Reducer<ScreenShareState, AnyAction>,
		slides: slidesRedux.reducer,
		whiteboards: whiteboardsRedux.reducer,
		notepads: notepadsRedux.reducer,
		wordCloud: wordCloudRedux.reducer as Reducer<WordCloudState, AnyAction>,
		multipleChoicePolling: multipleChoicePollingRedux.reducer as Reducer<
			MultipleChoicePollingState,
			AnyAction
		>,
		listPolling: listPollingRedux.reducer as Reducer<
			ListPollingState,
			AnyAction
		>,
		knowledgeChecks: knowledgeChecksReducer,
		users: usersReducer as Reducer<UsersState, AnyAction>,
		session: sessionReducer as Reducer<SessionState, AnyAction>,
		groupEngagement: groupEngagementReducer,
		script: scriptRedux.reducer,
		organization: organizationReducer.reducer as Reducer<
			OrganizationState,
			AnyAction
		>,
		audioStreaming: audioStreamingRedux.reducer as Reducer<
			AudioStreamingState,
			AnyAction
		>,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}).concat(usersMiddleware, sessionInfoMiddleware),
})

export type RootState = ReturnType<typeof rootStore.getState>
export type AppDispatch = typeof rootStore.dispatch
