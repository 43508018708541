export const PauseIcon = (): JSX.Element => (
	<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g strokeWidth="0"></g>
		<g strokeLinecap="round" strokeLinejoin="round"></g>
		<g>
			<path
				d="M8 5V19M16 5V19"
				stroke="currentColor"
				strokeWidth="4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
)
