import { RootState } from 'common/redux'
import { AudioStreamState } from './model'

export type AudioStreamOffsetRequested = {
	request: {
		meRequesting: boolean
		requested: boolean
		requestTimestamp: number | undefined
	}
	answer: {
		answered: boolean
		answerTimestamp: number | undefined
		ignoreFurtherOffsetChanges: boolean
	}
}
export type AudioStreamUserReadiness = {
	ready: boolean
}

export type AudioStreamingState = {
	userReadiness: {
		ready: boolean
	}
	audioStreamState?: AudioStreamState
	offsetRequested: AudioStreamOffsetRequested
}

const initialState: AudioStreamingState = {
	userReadiness: {
		ready: false,
	},
	offsetRequested: {
		request: {
			meRequesting: false,
			requested: false,
			requestTimestamp: undefined,
		},
		answer: {
			answered: false,
			answerTimestamp: undefined,
			ignoreFurtherOffsetChanges: false,
		},
	},
}

type AudioStreamingAction =
	| {
			type: 'SSE_AUDIO_STREAM_CHOSEN'
			payload: { domainEvent: AudioStreamState }
	  }
	| {
			type: 'SSE_AUDIO_STREAM_REMOVED'
			payload: {
				id: string
			}
	  }
	| {
			type: 'SSE_AUDIO_STREAM_OFFSET_REQUESTED'
			payload: {
				id: string
			}
	  }
	| {
			type: 'SSE_AUDIO_STREAM_OFFSET_RECEIVED'
			payload: { domainEvent: AudioStreamState }
	  }
	| {
			type: 'SSE_AUDIO_STREAMING_STARTED'
			payload: { domainEvent: AudioStreamState }
	  }
	| {
			type: 'SSE_AUDIO_STREAMING_PAUSED'
			payload: { domainEvent: AudioStreamState }
	  }
	| {
			type: 'SSE_AUDIO_STREAMING_FINISHED'
			payload: { domainEvent: AudioStreamState }
	  }
	| {
			type: 'AUDIO_STREAMING_OFFSET_PROVIDED'
	  }
	| {
			type: 'AUDIO_STREAMING_I_REQUESTED_OFFSET'
	  }
	| {
			type: 'AUDIO_STREAMING_READY_TO_PLAY'
	  }

export function reducer(
	state: AudioStreamingState = initialState,
	action: AudioStreamingAction
): AudioStreamingState {
	switch (action.type) {
		case 'SSE_AUDIO_STREAM_CHOSEN': {
			if (state.offsetRequested.answer.ignoreFurtherOffsetChanges) {
				return state
			}
			return {
				...state,
				audioStreamState: action.payload.domainEvent,
			}
		}
		case 'SSE_AUDIO_STREAM_REMOVED': {
			return {
				...state,
				audioStreamState: undefined,
			}
		}
		case 'SSE_AUDIO_STREAM_OFFSET_REQUESTED': {
			if (state.offsetRequested.request.meRequesting) {
				return state
			}
			return {
				...state,
				offsetRequested: {
					request: {
						...state.offsetRequested.request,
						requested: true,
						requestTimestamp: Date.now(),
					},
					answer: {
						ignoreFurtherOffsetChanges: false,
						answered: false,
						answerTimestamp: undefined,
					},
				},
			}
		}
		case 'SSE_AUDIO_STREAM_OFFSET_RECEIVED': {
			if (state.offsetRequested.request.meRequesting) {
				return {
					...state,
					userReadiness: {
						ready: true,
					},
					audioStreamState: action.payload.domainEvent,
					offsetRequested: {
						request: {
							meRequesting: false,
							requestTimestamp: undefined,
							requested: false,
						},
						answer: {
							answered: true,
							ignoreFurtherOffsetChanges: true,
							answerTimestamp: Date.now(),
						},
					},
				}
			}
			return {
				...state,
				offsetRequested: {
					request: {
						...state.offsetRequested.request,
						requestTimestamp: undefined,
						requested: false,
					},
					answer: {
						ignoreFurtherOffsetChanges: true,
						answered: true,
						answerTimestamp: Date.now(),
					},
				},
			}
		}
		case 'SSE_AUDIO_STREAMING_FINISHED': {
			return {
				...state,
				audioStreamState: action.payload.domainEvent,
				offsetRequested: {
					...state.offsetRequested,
					answer: {
						...state.offsetRequested.answer,
						ignoreFurtherOffsetChanges: false,
					},
				},
			}
		}
		case 'SSE_AUDIO_STREAMING_STARTED': {
			return {
				...state,
				userReadiness: {
					ready: true,
				},
				audioStreamState: action.payload.domainEvent,
				offsetRequested: {
					...state.offsetRequested,
					answer: {
						...state.offsetRequested.answer,
						ignoreFurtherOffsetChanges: false,
					},
				},
			}
		}
		case 'SSE_AUDIO_STREAMING_PAUSED': {
			return {
				...state,
				audioStreamState: action.payload.domainEvent,
				offsetRequested: {
					...state.offsetRequested,
					answer: {
						...state.offsetRequested.answer,
						ignoreFurtherOffsetChanges: false,
					},
				},
			}
		}
		case 'AUDIO_STREAMING_I_REQUESTED_OFFSET': {
			return {
				...state,
				userReadiness: {
					ready: false,
				},
				offsetRequested: {
					request: {
						requested: true,
						requestTimestamp: Date.now(),
						meRequesting: true,
					},
					answer: {
						ignoreFurtherOffsetChanges: false,
						answered: false,
						answerTimestamp: undefined,
					},
				},
			}
		}
		case 'AUDIO_STREAMING_READY_TO_PLAY': {
			return {
				...state,
				userReadiness: {
					ready: true,
				},
			}
		}

		default:
			return state
	}
}

const selectAudioStreamState = (
	state: RootState
): AudioStreamState | undefined => state.audioStreaming.audioStreamState

const selectAudioStreamingOffsetRequested = (
	state: RootState
): AudioStreamOffsetRequested => state.audioStreaming.offsetRequested

const selectAudioStreamingUserReadiness = (
	state: RootState
): AudioStreamUserReadiness => state.audioStreaming.userReadiness

const readyToPlayAudioStream = (): AudioStreamingAction => ({
	type: 'AUDIO_STREAMING_READY_TO_PLAY',
})

const provideOffset = (): AudioStreamingAction => ({
	type: 'AUDIO_STREAMING_OFFSET_PROVIDED',
})

const iRequestOffset = (): AudioStreamingAction => ({
	type: 'AUDIO_STREAMING_I_REQUESTED_OFFSET',
})

//ACTIOS
export { provideOffset, iRequestOffset, readyToPlayAudioStream }

//SELECTORS
export {
	selectAudioStreamState,
	selectAudioStreamingOffsetRequested,
	selectAudioStreamingUserReadiness,
}

export const redux = {
	reducer,
}
