import { useRef } from 'react'

type Return<T> = {
	current: T | null
	previous: T | null
}

export function usePrevious<T>(value: T): Return<T> {
	const ref = useRef<{ current: T | null; previous: T | null }>({
		current: value,
		previous: null,
	})

	const current = ref.current.current

	if (value !== current) {
		ref.current = {
			current: value,
			previous: current,
		}
	}

	return ref.current
}
