import { FocusedFeatureTypes } from 'common/types'
import ConfiguredKnowledgeCheckViewTrainee from 'features/knowledge-checks/configured-knowledge-check-view-trainee'
import { ConfiguredNotepad } from 'features/notepad'
import ListPolling from 'features/pollings/list-polling/list-polling'
import ConfiguredMultipleChoicePolling from 'features/pollings/multiple-choice-polling/configured-multiple-choice-polling'
import WordCloud from 'features/pollings/word-cloud/word-cloud'
import { ConfiguredScreenShare } from 'features/screen-share'
import { ConfiguredSlideViewer } from 'features/slides'
import { ConfiguredWhiteboard } from 'features/whiteboard'
import { useSelector } from 'react-redux'
import { ErrorWithStreams } from 'room/common/error-with-streams'
import { SceneVideoUser } from 'video-conference-media'
import ConfiguredIceBreakerTrainee from './ice-breaker/configured-ice-breaker-trainee'
import { selectFocusedFeature } from './redux'
import { SimpleGroupingFeature } from 'features/simple-grouping'
import { AudioStreamingUser } from 'features/audio-streaming/audio-streaming-user'

export const TraineeFocusedFeature: React.FC<{ streams: SceneVideoUser[] }> = ({
	streams,
}) => {
	const focusedFeature = useSelector(selectFocusedFeature)

	return (
		<>
			{(() => {
				switch (focusedFeature.type) {
					case FocusedFeatureTypes.ICEBREAKER:
						return <ConfiguredIceBreakerTrainee />
					case FocusedFeatureTypes.KNOWLEDGE_CHECK:
						return (
							<ConfiguredKnowledgeCheckViewTrainee
								knowledgeCheckId={focusedFeature.knowledgeCheckId}
							/>
						)
					case FocusedFeatureTypes.ScreenShare:
						return <ConfiguredScreenShare streams={streams} />
					case FocusedFeatureTypes.POLLING_OPEN_ENDED_WORD_CLOUD:
						return <WordCloud isTrainer={false} streams={streams} />
					case FocusedFeatureTypes.SLIDE:
						return <ConfiguredSlideViewer />
					case FocusedFeatureTypes.POLLING_OPEN_ENDED_LIST:
						return <ListPolling isTrainer={false} streams={streams} />
					case FocusedFeatureTypes.POLLING_MULTIPLE_CHOICE:
						return (
							<ConfiguredMultipleChoicePolling
								isTrainer={false}
								streams={streams}
							/>
						)
					case FocusedFeatureTypes.WHITEBOARD:
						return <ConfiguredWhiteboard isTrainer={false} streams={streams} />
					case FocusedFeatureTypes.NOTEPAD:
						return <ConfiguredNotepad isTrainer={false} streams={streams} />
					case FocusedFeatureTypes.SIMPLE_GROUPING:
						return <SimpleGroupingFeature streams={streams} />
					case FocusedFeatureTypes.AUDIO_STREAMING:
						return <AudioStreamingUser streams={streams} />
					default:
						return <ErrorWithStreams />
				}
			})()}
		</>
	)
}
