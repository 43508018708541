export const colors = {
	athensGray: '#F5F5F7',
	black10: 'rgba(0, 0, 0, 0.1)',
	black15: 'rgba(19, 16, 13, 0.15)',
	black50: 'rgba(0,0,0,0.5)',
	black70: 'rgba(0,0,0,0.7)',
	black: '#000000',
	blackish: '#293649',
	blue: '#4e83fa',
	blueGroup: '#49c9e6',
	blueyPurple: '#6d3cca',
	borderGray50: 'rgba(195, 202, 217, 0.5)',
	borderGray: '#C3CAD9',
	borderLightGray: '#ebebeb',
	borderLightGray50: 'rgba(235, 235, 235, 0.5)',
	brightCyan: '#59fded',
	brighterGray: '#FBFBFB',
	brightGray: '#ebebeb',
	darkBlue: '#3b3e53',
	darkGray30: 'rgba(160, 153, 167, 0.3)',
	darkGray5: 'rgba(160, 153, 167, 0.05)',
	darkGray: '#d8d8e5',
	darkIndigo: '#31018f',
	darkIndigo15: 'rgba(49, 1, 143, 0.15)',
	darkViolet: '#31028f',
	dustyOrange: '#f49126',
	dustyWhite: '#f3f3f7',
	fuchsia: '#d510c4',
	gray: '#babbd3',
	green: '#0ac6bf',
	lightGrayishBlue: '#ced6db',
	greenishCyan02: 'rgba(74, 227, 168, 0.2)',
	greenishCyan03: 'rgba(74, 227, 168, 0.3)',
	greenishCyan: '#4ae3a8',
	greenishTeal: '#33ce92',
	heather05: 'rgba(160, 153, 167, 0.05)',
	heather10: 'rgba(160, 153, 167, 0.1)',
	heather20: 'rgba(160, 153, 167, 0.2)',
	heather30: 'rgba(160, 153, 167, 0.3)',
	heather40: 'rgba(160, 153, 167, 0.4)',
	heather50: 'rgba(160, 153, 167, 0.5)',
	heather: '#a099a7',
	hospitalGreen: '#23beb6',
	inactiveGray: '#e9eaed',
	indigo: '#31018f',
	indigoBlue05: '#f2effa',
	indigoBlue10: '#ede7f9',
	indigoBlue30: '#CAB9EB',
	indigoBlue03: 'rgba(79, 23, 190, 0.3)',
	indigoBlue: '#4f17be',
	lighterIndigoBlue: '#612fc5',
	lightGray: '#999aaf',
	lightGreen: '#b0ffe1',
	lightIndigo: '#9570d8',
	lightishPurple: '#bb56d6',
	lightPink: '#FED7E1',
	lightPurple: '#dcd0f2',
	ligthBlue: '#3494dd',
	lipstick: '#db1864',
	marlinBlue: '#5669ff',
	paleGray2: '#f0f1f3',
	paleGray30: 'rgba(239, 239, 240, 0.3)',
	paleGray: '#f8f5ff',
	pinkRed: '#f1315e',
	pinkRed95: 'rgba(241, 49, 94, 0.95)',
	pinkRed15: 'rgba(241, 49, 94, 0.15)',
	red: '#ca4b3c',
	strongPink30: 'rgba(252, 15, 108, 0.3)',
	strongPink: '#fc0f6c',
	transparentBlackish: 'rgba(41, 54, 73, 0.7)',
	transparentGreenishCyan: '#b5e1d1',
	transparentPaleGray: 'rgba(41, 54, 73, 0.7)',
	transparentStrongPink: '#fbb3d0',
	transparentViolet: 'rgba(71, 19, 179, 0.9)',
	violet: 'rgb(71, 19, 179)',
	warmGrey: '#707070',
	white70: 'rgba(255, 255, 255, 0.7)',
	white: '#ffffff',
	whitish50: 'rgba(249, 250, 252, 0.5)',
	whitish: '#f9fafc',
	yellowish: '#ffeab0',
	gold: '#ffcf40',
	gold15: 'rgba(255, 207, 64, 0.15)',
	vividGreen: 'rgb(46, 184,46)',
}

export const BRAND_COLORS = {
	INDIGO: colors.indigoBlue,
	WHITE: '#ffffff',
	PURPLE: '#bb56d6',
	GRAY: '#a19e99',
	ORANGE: '#fc6920',
	NAVY: '#063d66',
	YELLOW: '#fdb92e',
	MINT: '#7bdcb5',
	GREEN: '#22d084',
	AZURE: '#8ed1fc',
	BLUE: '#1b93e3',
	GREY: '#abb8c3',
	RED: '#eb154c',
	PINK: '#f78ca7',
	VIOLET: '#9913ef',
}

export default colors
