import { http } from '@beelday/common'
import { RoomAddress } from 'common/types'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AudioStreamDefinition, AudioStreamId } from './model'
import { Config } from 'common/config'

export type AudioStreamApi = {
	create: (
		address: RoomAddress,
		audiStreamDefinition: AudioStreamDefinition
	) => Promise<AudioStreamDefinition>
	current: (
		address: RoomAddress,
		audioStreamId: AudioStreamId
	) => Promise<AudioStreamDefinition>
	removeCurrent: (address: RoomAddress) => Promise<void>
	play: (address: RoomAddress, offset: number) => Promise<void>
	pause: (address: RoomAddress, offset: number) => Promise<void>
	finish: (address: RoomAddress) => Promise<void>
	requestOffset: (address: RoomAddress) => Promise<void>
	provideOffset: (address: RoomAddress, offset: number) => Promise<void>
	all: (address: RoomAddress) => Promise<Array<AudioStreamDefinition>>
}

export const createAudioStreamingApi = (serverUrl: string): AudioStreamApi => ({
	create: (address, audioStreamDefinition) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/create/${audioStreamDefinition.id}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
				body: JSON.stringify({
					...audioStreamDefinition,
					type: 'AUDIO_STREAMING',
				}),
			}
		).then(http.checkStatus),
	current: (address, audioStreamId) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/current/${audioStreamId}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	removeCurrent: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/current`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	play: (address, offset) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/play/${offset}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	pause: (address, offset) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/pause/${offset}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	finish: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/finish`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	requestOffset: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/requestOffset`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	provideOffset: (address, offset) =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/provideOffset/${offset}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		).then(http.checkStatus),
	all: address =>
		fetch(
			`${serverUrl}/api/v0/workflows/${address.workflowId}/interactionschemes/${address.interactionSchemeId}/rooms/${address.roomId}/features/audio-stream/all`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'PB-Correlation-Id': uuidv4().replaceAll('-', '').slice(0, 16),
				},
			}
		)
			.then(http.checkStatus)
			.then(http.parseBody),
})

export const useAudioStreamingApi = (): AudioStreamApi => {
	return useMemo(() => createAudioStreamingApi(Config.beeldayBackendUrl), [])
}

export default useAudioStreamingApi
