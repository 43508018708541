import type { PlayerRanking } from './ice-breaker/types'

import { GroupsSuggestionAssignment } from 'room/training-room/set-groups/model/groups-suggestion-assignment'
import {
	FileId,
	GroupId,
	GroupPartitionMap,
	QuizAnswerId,
	QuizQuestionAnswered,
	QuizQuestionAsked,
	QuizQuestionCompleted,
	QuizId,
	QuizUser,
	FocusFeature,
	FocusedFeatureTypes,
	QuizFocusFeature,
} from 'common/types'
import { RootState } from 'common/redux'
import {
	PlacedScore,
	PlayersChangedEvent,
	PlayerScore,
	QuizConcludedEvent,
} from 'connectivity/beelday-client'
import { mapValues } from 'lodash'
import { createSelector } from 'reselect'

const trainingRoomReducerDefaultState: TrainingRoomState = {
	groupingDefinitionsAvailability: false,
	groupsSuggestionAssignment: new GroupsSuggestionAssignment([]),
	groupPartitionMap: [],
	reviewActiveMedia: null,
	quizzes: {},
	focusedFeatures: [],
}

type QuestionAskedQuizState = {
	number: number
	totalQuestions: number
	text: string
	imageId?: FileId
	answerTexts: Record<QuizAnswerId, string>
}

type QuestionAnsweredQuizState = QuestionAskedQuizState & {
	numberOfAnswers: number
	expectedNumberOfAnswers: number
	selectedAnswers: Record<QuizAnswerId, QuizUser[]>
}

type QuestionCompletedQuizState = QuestionAnsweredQuizState & {
	correctAnswers: QuizAnswerId[]
}

type QuizRankingState = {
	thereAreNoWinners: boolean
	totalQuestions: number
	firstPlace: PlacedScore
	secondPlace: PlacedScore
	thirdPlace: PlacedScore
	players: PlayerRanking[]
}

export type QuizState =
	| {
			phase: 'question-asked'
			quizId: string
			state: QuestionAskedQuizState
	  }
	| {
			phase: 'question-answered'
			quizId: string
			state: QuestionAnsweredQuizState
	  }
	| {
			phase: 'question-completed'
			quizId: string
			state: QuestionCompletedQuizState
	  }
	| {
			phase: 'quiz-concluded'
			quizId: string
			ranking: QuizRankingState
	  }

export interface TrainingRoomState {
	groupingDefinitionsAvailability: boolean
	groupsSuggestionAssignment: GroupsSuggestionAssignment
	groupPartitionMap: GroupPartitionMap
	reviewActiveGroup?: GroupId
	reviewActiveMedia: 'ETHERPAD' | 'EXCALIDRAW' | null
	quizzes: Record<QuizId, QuizState>
	focusedFeatures: FocusFeature[]
}

type TrainingRoomAction =
	| {
			type: 'SET_GROUPS_SUGGESTION_ASSIGNMENT'
			groupsSuggestionAssignment: GroupsSuggestionAssignment
	  }
	| { type: 'SET_GROUP_PARTITION_MAP'; groupPartitionMap: GroupPartitionMap }
	| { type: 'SET_REVIEW_ACTIVE_GROUP'; id: GroupId }
	| { type: 'HANDLE_REVIEW_BOARD_CLOSED'; groupId: GroupId }
	| { type: 'HANDLE_REVIEW_BOARD_OPENED'; groupId: GroupId }
	| {
			type: 'HANDLE_REVIEW_BOARD_GROUP_CHANGED'
			groupId: GroupId
			previousGroup: GroupId
	  }
	| { type: 'HANDLE_REVIEW_EXCALIDRAW_CLOSED'; groupId: GroupId }
	| { type: 'HANDLE_REVIEW_EXCALIDRAW_OPENED'; groupId: GroupId }
	| {
			type: 'HANDLE_REVIEW_EXCALIDRAW_GROUP_CHANGED'
			groupId: GroupId
			previousGroup: GroupId
	  }
	| { type: 'HANDLE_REVIEW_ETHER_PAD_CLOSED'; groupId: GroupId }
	| { type: 'HANDLE_REVIEW_ETHER_PAD_OPENED'; groupId: GroupId }
	| {
			type: 'HANDLE_REVIEW_ETHER_PAD_GROUP_CHANGED'
			groupId: GroupId
			previousGroup: GroupId
	  }
	| { type: 'HANDLE_QUESTION_ASKED'; event: QuizQuestionAsked }
	| { type: 'HANDLE_QUESTION_ANSWERED'; event: QuizQuestionAnswered }
	| { type: 'HANDLE_QUESTION_COMPLETED'; event: QuizQuestionCompleted }
	| { type: 'HANDLE_QUIZ_CONCLUDED'; event: QuizConcludedEvent }
	| { type: 'HANDLE_PLAYERS_CHANGED'; event: PlayersChangedEvent }
	| {
			type: 'SSE_FEATURE_FOCUSED'
			payload: {
				focusedFeatures: FocusFeature[]
			}
	  }
	| {
			type: 'SSE_GROUPING_DEFINITIONS_AVAILABILITY'
			payload: {
				available: boolean
			}
	  }
	| {
			type: 'HANDLE_SCREEN_SHARE_STARTED'
			groupId: GroupId
	  }
	| {
			type: 'HANDLE_SCREEN_SHARE_STOPPED'
			groupId: GroupId
	  }

export const setGroupsSuggestionAssignment = (
	groupsSuggestionAssignment: GroupsSuggestionAssignment
): TrainingRoomAction => {
	return {
		type: 'SET_GROUPS_SUGGESTION_ASSIGNMENT',
		groupsSuggestionAssignment,
	}
}

export const setGroupPartitionMap = (
	groupPartitionMap: GroupPartitionMap
): TrainingRoomAction => {
	return { type: 'SET_GROUP_PARTITION_MAP', groupPartitionMap }
}

export const setReviewActiveGroup = (id: GroupId): TrainingRoomAction => ({
	type: 'SET_REVIEW_ACTIVE_GROUP',
	id,
})

export const handleReviewExcalidrawOpened = (
	groupId: GroupId
): TrainingRoomAction => {
	return { type: 'HANDLE_REVIEW_EXCALIDRAW_OPENED', groupId }
}

export const handleReviewExcalidrawClosed = (
	groupId: GroupId
): TrainingRoomAction => {
	return { type: 'HANDLE_REVIEW_EXCALIDRAW_CLOSED', groupId }
}

export const handleReviewExcalidrawGroupSwitched = (
	groupId: GroupId,
	previousGroup: GroupId
): TrainingRoomAction => {
	return {
		type: 'HANDLE_REVIEW_EXCALIDRAW_GROUP_CHANGED',
		groupId,
		previousGroup,
	}
}

export const handleReviewScreenShareStarted = (
	groupId: GroupId
): TrainingRoomAction => {
	return { type: 'HANDLE_SCREEN_SHARE_STARTED', groupId }
}

export const handleReviewScreenShareStopped = (
	groupId: GroupId
): TrainingRoomAction => {
	return { type: 'HANDLE_SCREEN_SHARE_STOPPED', groupId }
}

export const handleReviewEtherPadOpened = (
	groupId: GroupId
): TrainingRoomAction => {
	return { type: 'HANDLE_REVIEW_ETHER_PAD_OPENED', groupId }
}

export const handleReviewEtherPadClosed = (
	groupId: GroupId
): TrainingRoomAction => {
	return { type: 'HANDLE_REVIEW_ETHER_PAD_CLOSED', groupId }
}

export const handleReviewEtherPadGroupSwitched = (
	groupId: GroupId,
	previousGroup: GroupId
): TrainingRoomAction => {
	return {
		type: 'HANDLE_REVIEW_ETHER_PAD_GROUP_CHANGED',
		groupId,
		previousGroup,
	}
}

export const handleQuestionAsked = (
	event: QuizQuestionAsked
): TrainingRoomAction => {
	return { type: 'HANDLE_QUESTION_ASKED', event }
}

export const handleQuestionAnswered = (
	event: QuizQuestionAnswered
): TrainingRoomAction => {
	return { type: 'HANDLE_QUESTION_ANSWERED', event }
}

export const handleQuestionCompleted = (
	event: QuizQuestionCompleted
): TrainingRoomAction => {
	return { type: 'HANDLE_QUESTION_COMPLETED', event }
}

export const handleQuizConcluded = (
	event: QuizConcludedEvent
): TrainingRoomAction => {
	return { type: 'HANDLE_QUIZ_CONCLUDED', event }
}

export const handlePlayersChanged = (
	event: PlayersChangedEvent
): TrainingRoomAction => {
	return { type: 'HANDLE_PLAYERS_CHANGED', event }
}

export function trainingRoomReducer(
	state: TrainingRoomState = trainingRoomReducerDefaultState,
	action: TrainingRoomAction
): TrainingRoomState {
	switch (action.type) {
		case 'SET_GROUPS_SUGGESTION_ASSIGNMENT':
			return {
				...state,
				groupsSuggestionAssignment: action.groupsSuggestionAssignment,
			}

		case 'SET_GROUP_PARTITION_MAP':
			return {
				...state,
				groupPartitionMap: action.groupPartitionMap,
			}

		case 'SET_REVIEW_ACTIVE_GROUP':
			return {
				...state,
				reviewActiveGroup: action.id,
			}
		case 'HANDLE_REVIEW_EXCALIDRAW_CLOSED':
			return {
				...state,
				reviewActiveMedia: null,
			}
		case 'HANDLE_REVIEW_EXCALIDRAW_OPENED':
			return {
				...state,
				reviewActiveMedia: 'EXCALIDRAW',
			}
		case 'HANDLE_REVIEW_EXCALIDRAW_GROUP_CHANGED':
			return {
				...state,
				reviewActiveMedia: 'EXCALIDRAW',
			}
		case 'HANDLE_REVIEW_ETHER_PAD_CLOSED':
			return {
				...state,
				reviewActiveMedia: null,
			}
		case 'HANDLE_REVIEW_ETHER_PAD_OPENED':
			return {
				...state,
				reviewActiveMedia: 'ETHERPAD',
			}
		case 'HANDLE_REVIEW_ETHER_PAD_GROUP_CHANGED':
			return {
				...state,
				reviewActiveMedia: 'ETHERPAD',
			}

		case 'HANDLE_QUESTION_ASKED':
			const updatedQuiz = {
				phase: 'question-asked' as const,
				quizId: action.event.quizId,
				state: {
					number: action.event.number,
					totalQuestions: action.event.totalQuestions,
					text: action.event.text,
					imageId: action.event.imageId?.length
						? action.event.imageId
						: undefined,
					answerTexts: action.event.availableAnswers.reduce(
						(accumulator, current) => ({
							...accumulator,
							[current.id]: current.text,
						}),
						{} as Record<QuizAnswerId, string>
					),
				},
			}
			return {
				...state,
				quizzes: {
					...state.quizzes,
					[updatedQuiz.quizId]: updatedQuiz,
				},
			}
		case 'HANDLE_QUESTION_ANSWERED': {
			const quiz = state.quizzes[action.event.quizId]
			if (
				quiz?.phase !== 'question-asked' &&
				quiz?.phase !== 'question-answered'
			) {
				throw Error(
					`Quiz phase (${quiz?.phase}) is other than the required 'question-asked' or 'question-answered!`
				)
			}

			const updatedQuiz = {
				phase: 'question-answered' as const,
				quizId: action.event.quizId,
				state: {
					...quiz.state,
					numberOfAnswers: action.event.numberOfAnswers,
					expectedNumberOfAnswers: action.event.expectedNumberOfAnswers,
					selectedAnswers: action.event.availableAnswers.reduce(
						(accumulator, current) => ({
							...accumulator,
							[current.id]: current.playersWhoSelectedThisAnswer,
						}),
						{} as Record<QuizAnswerId, QuizUser[]>
					),
				},
			}
			return {
				...state,
				quizzes: {
					...state.quizzes,
					[updatedQuiz.quizId]: updatedQuiz,
				},
			}
		}
		case 'HANDLE_QUESTION_COMPLETED': {
			const quiz = state.quizzes[action.event.quizId]
			if (!quiz || quiz?.phase === 'quiz-concluded') {
				throw Error('Quiz is being completed before it is initiated!')
			}
			const updatedQuiz = {
				phase: 'question-completed' as const,
				quizId: action.event.quizId,
				state: {
					...quiz.state,
					numberOfAnswers: action.event.numberOfAnswers,
					expectedNumberOfAnswers: action.event.expectedNumberOfAnswers,
					selectedAnswers: action.event.availableAnswers.reduce(
						(accumulator, current) => ({
							...accumulator,
							[current.id]: current.playersWhoSelectedThisAnswer,
						}),
						{} as Record<QuizAnswerId, QuizUser[]>
					),
					correctAnswers: action.event.availableAnswers
						.filter(answer => answer.correct)
						.map(answer => answer.id),
				},
			}
			return {
				...state,
				quizzes: {
					...state.quizzes,
					[updatedQuiz.quizId]: updatedQuiz,
				},
			}
		}
		case 'HANDLE_QUIZ_CONCLUDED': {
			const updatedQuiz = {
				phase: 'quiz-concluded' as const,
				quizId: action.event.quizId,
				ranking: {
					...action.event,
					players: calculatePlacesForPlayers(action.event.players),
				},
			}
			return {
				...state,
				quizzes: {
					...state.quizzes,
					[updatedQuiz.quizId]: updatedQuiz,
				},
			}
		}
		case 'HANDLE_PLAYERS_CHANGED': {
			const { expectedNumberOfAnswers } = action.event
			const updated = mapValues(state.quizzes, quiz => {
				if (
					quiz.phase === 'question-answered' ||
					quiz.phase === 'question-completed'
				) {
					return {
						...quiz,
						state: {
							...quiz.state,
							expectedNumberOfAnswers,
						},
					}
				} else {
					return quiz
				}
			}) as Record<QuizId, QuizState>
			return { ...state, quizzes: updated }
		}
		case 'SSE_FEATURE_FOCUSED': {
			return {
				...state,
				focusedFeatures: action.payload.focusedFeatures,
			}
		}
		case 'SSE_GROUPING_DEFINITIONS_AVAILABILITY': {
			return {
				...state,
				groupingDefinitionsAvailability: action.payload.available,
			}
		}
		default:
			return state
	}
}

const calculatePlacesForPlayers = (players: PlayerScore[]): PlayerRanking[] => {
	const ranked: PlayerRanking[] = []

	return players.reduce((acc, p, currentIndex) => {
		if (currentIndex === 0) {
			return [{ ...p, place: 1 }]
		} else {
			const previous = acc[currentIndex - 1]
			if (p.score === previous.score) {
				return [...acc, { ...p, place: previous.place }]
			} else {
				return [...acc, { ...p, place: previous.place + 1 }]
			}
		}
	}, ranked)
}

export const reviewMediaActive = (state: RootState) =>
	state.trainingRoom.reviewActiveMedia

export const selectFocusedFeature = (state: RootState) =>
	state.trainingRoom.focusedFeatures[0]

export const isAudioStreamingFocused = (state: RootState) =>
	state.trainingRoom.focusedFeatures[0] &&
	state.trainingRoom.focusedFeatures[0].type ===
		FocusedFeatureTypes.AUDIO_STREAMING

export const selectGroupingDefinitionsAvailability = (
	state: RootState
): boolean => state.trainingRoom.groupingDefinitionsAvailability

export const selectActiveQuiz = createSelector(
	(state: RootState) => state.trainingRoom.quizzes,
	(state: RootState) => state.trainingRoom.focusedFeatures,
	(quizzes, focusedFeatures) => {
		const currentQuiz = focusedFeatures.find(
			feature => feature.type === FocusedFeatureTypes.ICEBREAKER
		) as QuizFocusFeature | undefined
		if (currentQuiz) {
			return quizzes[currentQuiz.quizId]
		}
		return undefined
	}
)
