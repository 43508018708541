import { intl, ui } from '@beelday/common'
import { useCallback, useEffect, useState } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import { AudioStreamingList } from './audio-stream-list'
import useAudioStreamingApi from './audio-streaming-api'
import { AudioStreamDefinition } from './model'

type Props = {
	onClose: () => void
}
export const StartAudioStream = ({ onClose }: Props): JSX.Element => {
	const address = useAssertedJoinedRoomAddress()
	const audioStramingApi = useAudioStreamingApi()
	const [audios, setAudios] = useState<Array<AudioStreamDefinition>>([])

	useEffect(() => {
		audioStramingApi.all(address).then(setAudios)
	}, [address, audioStramingApi])

	const handleSelect = useCallback(
		(audioId: string) => {
			audioStramingApi.current(address, audioId).then(onClose)
		},
		[address, audioStramingApi, onClose]
	)

	const handleCreate = useCallback(
		(audioStreamDefinition: AudioStreamDefinition) => {
			audioStramingApi.create(address, audioStreamDefinition).then(onClose)
		},
		[address, audioStramingApi, onClose]
	)

	return (
		<ui.RightDetailsPanel
			header={
				<ui.RightDetailsPanel.SubTitleAndTitle
					title={
						<intl.Translate>room_menu.audio_stream.start_title</intl.Translate>
					}
					subTitle={
						<intl.Translate>
							room_menu.audio_stream.start_subtitle
						</intl.Translate>
					}
				/>
			}
			onClose={onClose}
		>
			<ui.FlexColumn style={{ rowGap: '40px', paddingBottom: '20px' }}>
				<AudioStreamingList
					audios={audios}
					onSelect={handleSelect}
					onCreate={handleCreate}
				/>
			</ui.FlexColumn>
		</ui.RightDetailsPanel>
	)
}
