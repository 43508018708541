import { icons, intl } from '@beelday/common'
import { useSelector } from 'app-redux'
import { MenuToggleItem } from 'room/common/presentation/menu'
import { isAudioStreamingFocused } from 'room/training-room/redux'
import { Back } from 'room/common/presentation/icon/back'

type Props = {
	canStart: boolean
	onClick: () => void
}
const AudioStreamMenuItem = ({ canStart, onClick }: Props): JSX.Element => {
	const active = useSelector(isAudioStreamingFocused)
	return (
		<MenuToggleItem
			active={active}
			disabled={!canStart && !active}
			activeIcon={<Back />}
			icon={<icons.AudioStreamIcon />}
			onClick={onClick}
			label={<intl.Translate>room_menu.audio_streaming</intl.Translate>}
		/>
	)
}

export default AudioStreamMenuItem
