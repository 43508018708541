import { colors, intl, ui } from '@beelday/common'
import { css } from '@emotion/react'
import { useFormik } from 'formik'
import { useCallback, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AudioStreamDefinition } from './model'

type Props = {
	onCreate: (audioStreamDefinition: AudioStreamDefinition) => void
}
export const AudioStreamForm = ({ onCreate }: Props): JSX.Element => {
	const uploaderRef = useRef<HTMLElement>(null)

	const formik = useFormik<AudioStreamDefinition>({
		initialValues: {
			id: uuidv4(),
			fileName: '',
			name: '',
			description: '',
		},
		validate: values => {
			const errors: Partial<AudioStreamDefinition> = {}
			if (!values.name) {
				errors.name = 'Required'
			}

			if (!values.fileName) {
				errors.fileName = 'Required'
			}

			return errors
		},
		onSubmit: values => {
			onCreate(values)
		},
	})

	const onUploadDone = useCallback(
		data => {
			formik.setFieldValue('fileName', data.fileName)
		},
		[formik]
	)

	useEffect(() => {
		const element = uploaderRef.current
		if (element) {
			const uploadAudioStreamFileResultListener = (customEvent: Event) => {
				const uploadResult = (customEvent as CustomEvent).detail
				onUploadDone(uploadResult)
			}

			element.addEventListener(
				'upload-done',
				uploadAudioStreamFileResultListener
			)
			return () => {
				element.removeEventListener(
					'upload-done',
					uploadAudioStreamFileResultListener
				)
			}
		}
	}, [onUploadDone])

	return (
		<ui.FlexColumnCenter style={{ width: '100%', rowGap: '20px' }}>
			<ui.InputLabeled
				label={<intl.Translate>audio_stream.form.name</intl.Translate>}
				invalid={!!formik.errors.name}
				css={css`
					width: 100%;
				`}
			>
				<ui.Input
					name="name"
					value={formik.values.name}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					maxLength={50}
					css={css`
						width: 100%;
					`}
				/>
			</ui.InputLabeled>
			<ui.InputLabeled
				label={<intl.Translate>audio_stream.form.description</intl.Translate>}
				invalid={!!formik.errors.description}
				css={css`
					width: 100%;
				`}
			>
				<ui.ExpandableTextAreaWithCounter
					name="description"
					maxHeight={200}
					value={formik.values.description}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					css={css`
						width: 100%;
						min-height: 100px;
						border: 1px solid ${colors.borderLightGray};
						font-size: 14px;
					`}
					limit={300}
				/>
			</ui.InputLabeled>

			<ui.FlexCenter style={{ maxWidth: '60%' }}>
				<beelday-training-audio-stream-upload ref={uploaderRef} />
			</ui.FlexCenter>

			<ui.Button
				disabled={Object.entries(formik.errors).length > 0 || !formik.dirty}
				style={{ width: '200px' }}
				onClick={formik.submitForm}
			>
				<intl.Translate>submit</intl.Translate>
			</ui.Button>
		</ui.FlexColumnCenter>
	)
}
