import { images, icons, intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { AudioStreamDefinition, AudioStreamId } from './model'
import { useCallback, useState } from 'react'
import { AudioStreamForm } from './audio-streaming-form'

const Container = styled(ui.FlexColumn)`
	row-gap: 20px;
	width: 100%;
`
type Props = {
	audios: Array<AudioStreamDefinition>
	onSelect: (audioId: AudioStreamId) => void
	onCreate: (audioStreamDefinition: AudioStreamDefinition) => void
}
export const AudioStreamingList = ({
	audios,
	onSelect,
	onCreate,
}: Props): JSX.Element => {
	const translate = intl.useTranslate()
	const [selected, setSelected] = useState<AudioStreamDefinition | null>(null)
	const [showForm, setShowForm] = useState(false)

	const handleCreate = useCallback(
		(audioStreamDefinition: AudioStreamDefinition) => {
			onCreate(audioStreamDefinition)
			setShowForm(false)
		},
		[onCreate]
	)

	return (
		<Container>
			{!showForm ? (
				<>
					<ui.ButtonLarge
						style={{
							width: '100%',
							alignSelf: 'center',
							justifySelf: 'center',
						}}
						icon={<icons.AudioStreamIcon />}
						onClick={() => setShowForm(true)}
					>
						<intl.Translate>room_menu.audio_stream.new</intl.Translate>
					</ui.ButtonLarge>
					{audios.length !== 0 ? (
						<>
							<ui.H3>
								<intl.Translate>
									room_menu.audio_stream.list.header
								</intl.Translate>
							</ui.H3>
							<ui.CheckboxList>
								{audios.map(audio => (
									<ui.CheckboxListItem
										key={audio.id}
										type="radio"
										name={audio.name}
										value={audio.id}
										checked={selected?.id === audio.id}
										imageSrc={images.audioTemplateIcon}
										onChange={checked => {
											if (checked) {
												setSelected(audio)
											}
										}}
									/>
								))}
							</ui.CheckboxList>
							{selected && (
								<ui.ButtonPrimary
									style={{ width: '200px' }}
									onClick={() => onSelect(selected.id)}
								>
									<intl.Translate>
										room_menu.audio_stream.start_button
									</intl.Translate>
								</ui.ButtonPrimary>
							)}
						</>
					) : (
						<div style={{ marginTop: '24px' }}>
							<ui.EmptyStateIconText
								icon={images.audioTemplateCircleIcon}
								title={translate(
									'room_menu.audio_stream.empty.from_tools.title'
								)}
								description={translate(
									'room_menu.audio_stream.empty.from_tools.description'
								)}
							/>
						</div>
					)}
				</>
			) : (
				<AudioStreamForm onCreate={handleCreate} />
			)}
		</Container>
	)
}
