export const AudioStreamIcon = (): JSX.Element => (
	<svg
		viewBox="2 2 17 17"
		width="24px"
		height="24px"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="g1" strokeWidth="0"></g>
		<g id="g2" strokeLinecap="round" strokeLinejoin="round"></g>
		<g id="g3">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.75 3.80989C6.75 3.29386 7.14265 2.86257 7.65642 2.81428L16.6564 1.96832C17.2431 1.91318 17.75 2.37468 17.75 2.96393V5.14519C17.75 5.65923 17.3603 6.08955 16.8487 6.1403L7.84873 7.03323C7.26031 7.09161 6.75 6.62943 6.75 6.03812V3.80989Z"
				fill="currentColor"
			></path>
			<ellipse cx="14.75" cy="15" rx="3" ry="2.5" fill="currentColor"></ellipse>
			<ellipse cx="5.75" cy="16" rx="3" ry="2.5" fill="currentColor"></ellipse>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.75 5H17.75V15H15.75V5Z"
				fill="currentColor"
			></path>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.75 6H8.75V16H6.75V6Z"
				fill="currentColor"
			></path>
		</g>
	</svg>
)
