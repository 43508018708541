import { colors, intl, ui, usePrevious } from '@beelday/common'
import { useAuthenticatedUser } from '@beelday/common/src/security'
import styled from '@emotion/styled'
import { useWavesurfer } from '@wavesurfer/react'
import { useDispatch, useSelector } from 'app-redux'
import { Config } from 'common/config'
import useMicApi from 'features/microphone/microphone-api'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import { SceneVideoUser } from 'video-conference-media'
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import useAudioStreamingApi from './audio-streaming-api'
import {
	AudioStreamOffsetRequested,
	AudioStreamUserReadiness,
	iRequestOffset,
	selectAudioStreamingOffsetRequested,
	selectAudioStreamingUserReadiness,
	selectAudioStreamState,
} from './audio-streaming-redux'
import { AudioStreamState } from './model'

type Props = {
	streams: Array<SceneVideoUser>
}

export const AudioStreamingUser = ({ streams }: Props): JSX.Element => {
	const audioStream = useSelector(selectAudioStreamState)
	const audioStreamOffsetRequested = useSelector(
		selectAudioStreamingOffsetRequested
	)
	const audioStreamUserReadiness = useSelector(
		selectAudioStreamingUserReadiness
	)
	return audioStream && audioStreamOffsetRequested && streams ? (
		<ConfiguredAudioStreamingUser
			audioStream={audioStream}
			audioStreamOffsetRequested={audioStreamOffsetRequested}
			audioUserReadiness={audioStreamUserReadiness}
			streams={streams}
		/>
	) : (
		<div>NOTHING</div>
	)
}

//wavesurfer + timeline
const HEIGHT_OF_WAVESURFER_CONTAINER = 120

const Container = styled.div`
	background-color: ${colors.white};
	border-radius: 18px 18px 18px 18px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 700px;
	overflow: auto;
	width: 100%;
	padding: 20px;
	row-gap: 20px;
	align-items: center;
`

const Title = styled.div`
	font-size: 28px;
	line-height: 36px;
	font-weight: 700;
	align-self: flex-start;
	text-align: center;
	word-break: break-all;

	${ui.responsive.desktop1440} {
		font-size: 24px;
		line-height: 30px;
	}
`
const ControlsContainer = styled(ui.Flex)`
	column-gap: 20px;
	padding: 10px;
	background-color: ${colors.lightPurple};
	width: fit-content;
	border-radius: 50px;
	justify-content: space-between;
	align-items: center; ;
`

type ConfiguredProps = {
	audioStream: AudioStreamState
	audioStreamOffsetRequested: AudioStreamOffsetRequested
	audioUserReadiness: AudioStreamUserReadiness
	streams: Array<SceneVideoUser>
}

export const ConfiguredAudioStreamingUser = ({
	audioStream,
	audioStreamOffsetRequested,
	audioUserReadiness,
	streams,
}: ConfiguredProps): JSX.Element => {
	const dispatch = useDispatch()
	const audioStreamingApi = useAudioStreamingApi()
	const address = useAssertedJoinedRoomAddress()
	const microphoneApi = useMicApi(Config.beeldayBackendUrl)
	const assertedUser = useAuthenticatedUser()

	const containerRef = useRef(null)

	const [currentTime, setCurrentTime] = useState(0)
	const [duration, setDuration] = useState(0)
	const [display, setDisplay] = useState(false)

	const { wavesurfer } = useWavesurfer({
		interact: false,
		container: containerRef,
		url: 'https://trainings-dev.eu-central-1.linodeobjects.com/session-planning/audio-stream/audio-a4332959-87ef-4a03-bbac-5a2de42a84ce.ogg',
		// Config.s3BucketUrl +
		// Config.audioStreamFolder +
		// '/' +
		// audioStream?.fileName,
		height: 100,
		width: 500,
		waveColor: colors.borderLightGray,
		progressColor: colors.indigoBlue,
		barGap: 2,
		barRadius: 2,
		barWidth: 4,
		cursorWidth: 2,
		normalize: true,
		plugins: useMemo(() => [Timeline.create()], []),
	})

	const { previous: previousState, current: currentState } = usePrevious(
		audioStream?.state
	)

	const playAudio = useCallback(() => {
		microphoneApi.muteMic(address, assertedUser.id).then(() => {
			if (audioUserReadiness.ready) {
				wavesurfer?.play()
			}
		})
	}, [
		address,
		assertedUser.id,
		audioUserReadiness.ready,
		microphoneApi,
		wavesurfer,
	])

	useEffect(() => {
		if (wavesurfer) {
			wavesurfer.setTime((audioStream?.offset || 0) / 1000)
			const internalAudio = wavesurfer.getMediaElement()
			if (internalAudio) {
				internalAudio.currentTime = audioStream?.offset
					? audioStream.offset / 1000
					: 0
			}
		}
	}, [audioStream?.offset, wavesurfer])

	useEffect(() => {
		//i am late
		if (!previousState && currentState === 'Playing') {
			if (
				!audioStreamOffsetRequested.request.meRequesting &&
				!audioUserReadiness.ready
			) {
				dispatch(iRequestOffset())
				microphoneApi.muteMic(address, assertedUser.id).then(() => {
					audioStreamingApi.requestOffset(address)
				})
			}

			if (audioUserReadiness.ready) {
				wavesurfer?.play()
			}
		}
		if (previousState === 'Stopped' && currentState === 'Playing') {
			playAudio()
		} else if (previousState === 'Paused' && currentState === 'Playing') {
			playAudio()
		} else if (currentState === 'Stopped') {
			wavesurfer?.pause()
		} else if (currentState === 'Paused') {
			wavesurfer?.pause()
		}
	}, [
		address,
		assertedUser.id,
		audioStreamOffsetRequested.request.meRequesting,
		audioStreamingApi,
		audioUserReadiness.ready,
		currentState,
		dispatch,
		microphoneApi,
		playAudio,
		previousState,
		wavesurfer,
	])

	useEffect(() => {
		if (
			audioStreamOffsetRequested.request.requested &&
			!audioStreamOffsetRequested.request.meRequesting &&
			!audioStreamOffsetRequested.answer.answered
		) {
			audioStreamingApi.provideOffset(address, Math.floor(currentTime * 1000))
		}
	}, [address, audioStreamOffsetRequested, audioStreamingApi, currentTime])

	useEffect(() => {
		if (!wavesurfer) return

		const handleAudioProcess = () => {
			setDisplay(true)
			setCurrentTime(wavesurfer.getCurrentTime())
			setDuration(wavesurfer.getDuration())
		}

		wavesurfer.on('seeking', handleAudioProcess)
		wavesurfer.on('ready', handleAudioProcess)
		wavesurfer.on('audioprocess', handleAudioProcess)
		return () => {
			wavesurfer.un('seeking', handleAudioProcess)
			wavesurfer.un('ready', handleAudioProcess)
			wavesurfer.un('audioprocess', handleAudioProcess)
		}
	}, [address, audioStream.state, audioStreamingApi, wavesurfer])

	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={streams}>
			<Container>
				<Title>{audioStream.name}</Title>
				<ui.FlexColumnCenter
					style={{
						rowGap: '20px',
						padding: '20px',
						border: `2px solid ${colors.borderLightGray}`,
						borderRadius: '8px',
						minWidth: '80%',
					}}
				>
					<ui.FlexColumnCenter
						ref={containerRef}
						style={{
							minHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
							maxHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
						}}
					/>
					{display === false && (
						<ui.FlexColumnCenter
							style={{
								position: 'absolute',
								minWidth: '80%',
								alignItems: 'center',
								minHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
								maxHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
								rowGap: '10px',
							}}
						>
							<ui.Spinner size={64} />
							<ui.H5
								style={{
									textAlign: 'center',
									marginTop: '10px',
								}}
							>
								<intl.Translate>audio_stream.load.header</intl.Translate>
							</ui.H5>
						</ui.FlexColumnCenter>
					)}
					<ControlsContainer>
						<ui.H5 style={{ color: colors.white }}>
							{formatTime(currentTime)} / {formatTime(duration)}
						</ui.H5>
					</ControlsContainer>
				</ui.FlexColumnCenter>

				<ui.H5
					style={{ maxWidth: '80%', marginTop: '20px', wordBreak: 'break-all' }}
				>
					{audioStream.description}
				</ui.H5>
			</Container>
		</ThreeColumnsVideoGrid>
	)
}
const formatTime = (time: number): string => {
	const minutes = Math.floor(time / 60)
	const seconds = Math.floor(time % 60)
		.toString()
		.padStart(2, '0')
	return `${minutes}:${seconds}`
}
