import { colors, icons, intl, ui, usePrevious } from '@beelday/common'
import { useAuthenticatedUser } from '@beelday/common/src/security'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useWavesurfer } from '@wavesurfer/react'
import { useDispatch, useSelector } from 'app-redux'
import { Config } from 'common/config'
import useMicApi from 'features/microphone/microphone-api'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAssertedJoinedRoomAddress } from 'room/common/use-joined-room-address'
import ThreeColumnsVideoGrid from 'room/training-room/ice-breaker/presentation/three-columns-video-grid'
import { SceneVideoUser } from 'video-conference-media'
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import useAudioStreamingApi from './audio-streaming-api'
import {
	AudioStreamOffsetRequested,
	AudioStreamUserReadiness,
	iRequestOffset,
	selectAudioStreamingOffsetRequested,
	selectAudioStreamingUserReadiness,
	selectAudioStreamState,
} from './audio-streaming-redux'
import { AudioStreamState } from './model'

type Props = {
	streams: Array<SceneVideoUser>
}
export const AudioStreamingTrainer = ({ streams }: Props): JSX.Element => {
	const audioStream = useSelector(selectAudioStreamState)
	const audioStreamOffsetRequested = useSelector(
		selectAudioStreamingOffsetRequested
	)
	const audioStreamUserReadiness = useSelector(
		selectAudioStreamingUserReadiness
	)

	return audioStream && audioStreamOffsetRequested && streams ? (
		<ConfiguredAudioStreamingTrainer
			audioStream={audioStream}
			audioStreamOffsetRequested={audioStreamOffsetRequested}
			audioUserReadiness={audioStreamUserReadiness}
			streams={streams}
		/>
	) : (
		<></>
	)
}

//wavesurfer + timeline
const HEIGHT_OF_WAVESURFER_CONTAINER = 120

const Container = styled.div`
	background-color: ${colors.white};
	border-radius: 18px 18px 18px 18px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 auto;
	max-width: 700px;
	overflow: auto;
	width: 100%;
	min-width: 100%;
	padding: 20px;
	row-gap: 20px;
	align-items: center;
`

const Title = styled.div`
	font-size: 28px;
	line-height: 36px;
	font-weight: 700;
	align-self: flex-start;
	text-align: center;
	word-break: break-all;

	${ui.responsive.desktop1440} {
		font-size: 24px;
		line-height: 30px;
	}
`

const ControlsContainer = styled(ui.Flex)`
	column-gap: 20px;
	padding: 10px;
	background-color: ${colors.lightPurple};
	width: fit-content;
	border-radius: 50px;
	justify-content: space-between;
	align-items: center; ;
`

type ConfiguredProps = {
	audioStream: AudioStreamState
	audioStreamOffsetRequested: AudioStreamOffsetRequested
	audioUserReadiness: AudioStreamUserReadiness
	streams: Array<SceneVideoUser>
}
export const ConfiguredAudioStreamingTrainer = ({
	audioStream,
	audioStreamOffsetRequested,
	audioUserReadiness,
	streams,
}: ConfiguredProps): JSX.Element => {
	const dispatch = useDispatch()
	const audioStreamingApi = useAudioStreamingApi()
	const address = useAssertedJoinedRoomAddress()
	const microphoneApi = useMicApi(Config.beeldayBackendUrl)
	const assertedUser = useAuthenticatedUser()
	const [currentTime, setCurrentTime] = useState(0)
	const [duration, setDuration] = useState(0)
	const [display, setDisplay] = useState(false)

	const { previous: previousState, current: currentState } = usePrevious(
		audioStream?.state
	)
	const seekIntent = useRef({
		intent: audioStream.state === 'Playing' ? 'Paused' : 'Playing',
		do: false,
	})

	const containerRef = useRef(null)

	const { wavesurfer } = useWavesurfer({
		container: containerRef,
		url:
			Config.s3BucketUrl +
			Config.audioStreamFolder +
			'/' +
			audioStream?.fileName,
		waveColor: colors.borderLightGray,
		progressColor: colors.indigoBlue,
		height: 100,
		width: 500,
		barGap: 2,
		barRadius: 2,
		barWidth: 4,
		cursorWidth: 2,
		normalize: true,

		plugins: useMemo(
			() => [
				Hover.create({
					lineColor: colors.pinkRed,
					lineWidth: 2,
					labelBackground: colors.heather,
					labelColor: colors.white,
					labelSize: '14px',
				}),
				Timeline.create(),
			],
			[]
		),
	})

	const onPlayPause = () => {
		const currentTimeMs = Math.floor((wavesurfer?.getCurrentTime() || 0) * 1000)

		if (audioStream.state === 'Playing') {
			seekIntent.current = { intent: 'Paused', do: true }

			audioStreamingApi
				.pause(address, currentTimeMs)
				.then(() => microphoneApi.unmuteMic(address, assertedUser.id))
		} else {
			seekIntent.current = { intent: 'Playing', do: true }
			audioStreamingApi.play(address, currentTimeMs)
		}
	}

	const playAudio = useCallback(() => {
		microphoneApi.muteMic(address, assertedUser.id).then(() => {
			if (audioUserReadiness.ready) {
				wavesurfer?.play()
			}
		})
	}, [
		address,
		assertedUser.id,
		audioUserReadiness.ready,
		microphoneApi,
		wavesurfer,
	])

	useEffect(() => {
		if (wavesurfer) {
			const internalAudio = wavesurfer.getMediaElement()

			if (internalAudio) {
				internalAudio.currentTime = audioStream?.offset
					? audioStream.offset / 1000
					: 0
			}
		}
	}, [audioStream?.offset, wavesurfer])

	useEffect(() => {
		//i am late
		if (!previousState && currentState === 'Playing') {
			if (
				!audioStreamOffsetRequested.request.meRequesting &&
				!audioUserReadiness.ready
			) {
				dispatch(iRequestOffset())
				microphoneApi.muteMic(address, assertedUser.id).then(() => {
					audioStreamingApi.requestOffset(address)
				})
			}

			if (audioUserReadiness.ready) {
				wavesurfer?.play()
			}
		} else if (previousState === 'Stopped' && currentState === 'Playing') {
			playAudio()
		} else if (previousState === 'Paused' && currentState === 'Playing') {
			playAudio()
		} else if (currentState === 'Stopped') {
			wavesurfer?.pause()
		} else if (currentState === 'Paused') {
			wavesurfer?.pause()
		}
	}, [
		address,
		assertedUser.id,
		audioStreamOffsetRequested.request.meRequesting,
		audioStreamingApi,
		audioUserReadiness.ready,
		currentState,
		dispatch,
		microphoneApi,
		playAudio,
		previousState,
		wavesurfer,
	])

	useEffect(() => {
		if (
			audioStreamOffsetRequested.request.requested &&
			!audioStreamOffsetRequested.request.meRequesting &&
			!audioStreamOffsetRequested.answer.answered
		) {
			audioStreamingApi.provideOffset(
				address,
				Math.floor((wavesurfer?.getCurrentTime() || 0) * 1000)
			)
		}
	}, [address, audioStreamOffsetRequested, audioStreamingApi, wavesurfer])

	useEffect(() => {
		if (!wavesurfer) return

		const handleAudioProcess = () => {
			setDisplay(true)
			setCurrentTime(wavesurfer.getCurrentTime())
			setDuration(wavesurfer.getDuration())
		}

		const handleFinish = () => {
			audioStreamingApi
				.finish(address)
				.then(() => microphoneApi.unmuteMic(address, assertedUser.id))
		}

		const handleClick = (progress: number) => {
			if (audioStream.state === 'Playing') {
				handleAudioProcess()
				audioStreamingApi.play(
					address,
					Math.floor(wavesurfer.getDuration() * progress * 1000)
				)
			} else {
				handleAudioProcess()
				audioStreamingApi.pause(
					address,
					Math.floor(wavesurfer.getDuration() * progress * 1000)
				)
			}
		}

		wavesurfer.on('seeking', handleAudioProcess)
		wavesurfer.on('finish', handleFinish)
		wavesurfer.on('click', handleClick)
		wavesurfer.on('ready', handleAudioProcess)
		wavesurfer.on('audioprocess', handleAudioProcess)
		return () => {
			wavesurfer.un('seeking', handleAudioProcess)
			wavesurfer.un('finish', handleFinish)
			wavesurfer.un('click', handleClick)
			wavesurfer.un('ready', handleAudioProcess)
			wavesurfer.un('audioprocess', handleAudioProcess)
		}
	}, [
		address,
		assertedUser.id,
		audioStream.state,
		audioStreamingApi,
		microphoneApi,
		wavesurfer,
	])

	return (
		<ThreeColumnsVideoGrid sceneVideoUsers={streams}>
			<Container>
				<ui.Flex
					style={{
						width: '100%',
						justifyContent: 'space-between',
						rowGap: '20px',
					}}
				>
					<Title>{audioStream.name}</Title>
					<ui.ButtonClose
						css={css`
							width: 36px;
							height: 36px;
							min-width: 36px;
						`}
						onClick={() =>
							audioStreamingApi
								.removeCurrent(address)
								.then(() => microphoneApi.unmuteMic(address, assertedUser.id))
						}
					/>
				</ui.Flex>
				<ui.FlexColumnCenter
					style={{
						rowGap: '20px',
						padding: '20px',
						border: `2px solid ${colors.borderLightGray}`,
						borderRadius: '8px',
						minWidth: '80%',
					}}
				>
					<ui.FlexColumnCenter
						ref={containerRef}
						style={{
							cursor: 'pointer',
							minWidth: '80%',
							alignItems: 'center',
							minHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
							maxHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
						}}
					/>
					{display === false && (
						<ui.FlexColumnCenter
							style={{
								position: 'absolute',
								minWidth: '80%',
								alignItems: 'center',
								minHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
								maxHeight: `${HEIGHT_OF_WAVESURFER_CONTAINER}px`,
								rowGap: '10px',
							}}
						>
							<ui.Spinner size={64} />
							<ui.H5
								style={{
									textAlign: 'center',
									marginTop: '10px',
								}}
							>
								<intl.Translate>audio_stream.load.header</intl.Translate>
							</ui.H5>
						</ui.FlexColumnCenter>
					)}

					<ControlsContainer>
						<button
							css={css`
								min-width: 48px;
								min-height: 48px;
								border-radius: 50%;
								border: 4px solid ${colors.borderLightGray};
								background-color: ${audioStream.state === 'Playing'
									? colors.pinkRed
									: colors.indigoBlue};
								color: ${colors.white};
								display: flex;
								align-items: center;
								justify-content: center;
								cursor: pointer;
								transition: 0.3s ease-in-out;

								&:hover {
									scale: 1.1;
								}
							`}
							onClick={onPlayPause}
						>
							<div
								css={css`
									width: 20px;
									height: 20px;
									svg {
										height: 20px;
										width: 20px;
									}
								`}
							>
								{audioStream.state === 'Playing' ? (
									<icons.PauseIcon />
								) : (
									<icons.PlayIcon />
								)}
							</div>
						</button>
						<ui.H2 style={{ color: colors.white }}>
							{formatTime(currentTime)} / {formatTime(duration)}
						</ui.H2>
					</ControlsContainer>
				</ui.FlexColumnCenter>

				<ui.H5
					style={{ maxWidth: '80%', marginTop: '20px', wordBreak: 'break-all' }}
				>
					{audioStream.description}
				</ui.H5>
			</Container>
		</ThreeColumnsVideoGrid>
	)
}
const formatTime = (time: number): string => {
	const minutes = Math.floor(time / 60)
	const seconds = Math.floor(time % 60)
		.toString()
		.padStart(2, '0')
	return `${minutes}:${seconds}`
}
