import { useSelector } from 'app-redux'
import { selectFocusedFeature } from 'room/training-room/redux'
import { selectAudioStreamState } from './audio-streaming-redux'
import { FocusedFeatureTypes } from 'common/types'

export const useDisableFeatureOnAudioStreamPlaying = (): boolean => {
	const focusedFeature = useSelector(selectFocusedFeature)
	const audioStreamingState = useSelector(selectAudioStreamState)

	return (
		focusedFeature?.type === FocusedFeatureTypes.AUDIO_STREAMING &&
		audioStreamingState?.state === 'Playing'
	)
}
